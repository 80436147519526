import { useStyles } from "./LoadingView.styles";
import AnteLogo from "assets/ante/ante-logo.svg";
import { Fade } from "@material-ui/core";

const LoadingView = () => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <Fade in={true} timeout={800}>
        <img
          src={AnteLogo}
          alt="Ante Protocol"
          height="80"
          className={styles.logo}
        />
      </Fade>
    </div>
  );
};

export { LoadingView };
