import { Network } from "@antefinance/ante-sdk";
import ArbitrumLogo from "assets/img/chains/arbitrum-logo.svg";
import AvalancheLogo from "assets/img/chains/avalanche-logo.svg";
import AuroraLogo from "assets/img/chains/aurora-logo.svg";
import ZkSyncLogo from "assets/img/chains/zkSync-logo.svg";
import BSCLogo from "assets/img/chains/bsc-logo.svg";
import EthereumLogo from "assets/img/chains/ethereum-logo.svg";
import EtherscanLogo from "assets/img/etherscan-logo.svg";
import FantomLogo from "assets/img/chains/fantom-logo.svg";
import OptimismLogo from "assets/img/chains/optimism-logo.svg";
import PolygonLogo from "assets/img/chains/polygon-logo.svg";
import ScrollLogo from "assets/img/chains/scroll-logo.svg";
import { Currency } from "types/Currency";
import { getSourceCodeFromExplorer } from "./apicall";
import { Address } from "types";
import { getNetworkById } from "./utils";
import WETHIcon from "assets/img/tokens/weth.png";
import USDCIcon from "assets/img/tokens/usdc.png";
import OPIcon from "assets/img/tokens/op.svg";
import { parseEther } from "@ethersproject/units";

// Amount of locked ETH to have trust score showing
export const TVL_THRESHOLD = 0;
// number of milliseconds in past for a pool to be considered "new"
export const NEW_THRESHOLD = 7 * 24 * 60 * 60 * 1000;

export const MIN_CHALLENGER_DELAY = 180;

export const MAX_INPUT_DECIMALS = 6;
export const ONE_DAY = 24 * 3600;
export const ONE_YEAR = 365 * ONE_DAY;

// Frontend token stake/challenge cap
type TokenCap = {
  [key in ChainId]: {
    min: number;
    max: number;
  };
};
export const TOKEN_CAP: TokenCap = {
  "0x1": {
    // mainnet
    min: 0.01,
    max: 50,
  },
  "0x4": {
    // rinkeby
    min: 0.01,
    max: 50,
  },
  "0x5": {
    // goerli
    min: 0.01,
    max: 50,
  },
  "0xa": {
    // optimism
    min: 0.01,
    max: 50,
  },
  "0x38": {
    // bsc
    min: 0.1,
    max: 500,
  },
  "0x61": {
    // bsc testnet
    min: 0.1,
    max: 500,
  },
  "0x89": {
    // polygon
    min: 1,
    max: 4000,
  },
  "0xfa": {
    // fantom
    min: 1,
    max: 4000,
  },
  "0x1a4": {
    // optimism goerli
    min: 1,
    max: 50,
  },
  "0xfa2": {
    // fantom testnet
    min: 1,
    max: 4000,
  },
  "0xa86a": {
    // avalanche fuji
    min: 1,
    max: 4000,
  },
  "0xa869": {
    // avalanche fuji
    min: 1,
    max: 4000,
  },
  "0x13881": {
    // polygon mumbai
    min: 1,
    max: 4000,
  },
  "0x8274f": {
    // scroll pre-alpha l1 testnet
    min: 0.01,
    max: 50,
  },
  "0x82751": {
    // scroll alpha testnet
    min: 0.01,
    max: 50,
  },
  "0x82752": {
    // scroll pre-alpha l2 testnet
    min: 0.01,
    max: 50,
  },
  "0xa4b1": {
    // arbitrumOne
    min: 0.01,
    max: 50,
  },
  "0x66eed": {
    // arbitrumGoerli
    min: 0.01,
    max: 50,
  },
  "0x4e454152": {
    // aurora
    min: 0.01,
    max: 50,
  },
  "0x4e454153": {
    // auroraTestnet
    min: 0.01,
    max: 50,
  },
  "0x144": {
    // zkSyncMainnet
    min: 0.01,
    max: 50,
  },
  "0x118": {
    // zkSyncTestnet
    min: 0.01,
    max: 50,
  },
};
// Estimated Gas Buffer
export const GAS_BUFFER = parseEther("0.03");

export const JSEAM_POOL = {
  testAddress: "0xBfE297e929276E3E6BF5a3daFf6E8E7aF2d09526",
  address: "0xE76EEe525C0Bc488340Ff91167981db4A5C6391f",
  name: "JSeam2",
  githubUrl: "https://github.com/JSeam2",
};

export const RESTRICTED_COUNTRIES = [
  "CU", // Cuba
  "IR", // Iran
  "KP", // North Korea
  "RU", // Russia
  "SY", // Syria
  "UA", // Ukraine
];

export const FRANKIE_POOL = {
  testAddress: "0x2c7f254F149AC99130bB480fB050df12308948f5",
  address: "0xc9279Bf55a7dC7cC6d2774435069074Ff5e19eC5",
  name: "FrankieIsLost",
  githubUrl: "https://github.com/FrankieIsLost",
};

export const LLAMA_POOLS = {
  "0x1": "0x18fCb9704D596Ac3cf912F3Bd390579b8c22684F", // eth mainnet
  "0x4": "0x8B29C1f916DD7d537D8438dF3A70f642eCf6794B", // eth rinkeby
  "0xa": "0x4572cf767f8f541858Aa67bc7B9325cF08542056", // optimism
  "0x38": "0x30092Af4E727249e93937EA8ae458b30d9Ae5cf5", // bsc
  "0x89": "0x2992EA84A041F5042CA0062d2E09178A589aEdcb", // polygon mainnet
  "0xfa": "0xBF02833C4a37C3CbE73A9339c069F09246307865", // fantom
  "0xa4b1": "0x73563B0034f5D15849070638B841bcFD225d8182", // arbitrum one
  "0xa86a": "0x99eDEcfE4FE9c2d760b30E782eA0E6C87Bd2F3ac", // avax mainnet
  "0xa869": "", // avax fuji
};

export const isMainnet = process.env.REACT_APP_ENV === "mainnet";

export const ANTE_GOLD_TRUST_THRESHOLD = parseFloat(
  process.env.REACT_APP_ANTE_GOLD_TRUST_THRESHOLD ?? "80"
);

export type TokenId = string;

export type TokenDetails = {
  providerId: string;
  symbol: string;
  decimals: number;
  logo?: string;
};

type TokenPriceProviderMap = {
  [key in ChainId]?: TokensMap;
};

type TokensMap = Record<TokenId, TokenDetails>;

export const TOKENS: TokensMap = {
  USDC: {
    providerId: "usd-coin",
    symbol: "USDC",
    decimals: 6,
    logo: USDCIcon,
  },
  WETH: {
    providerId: "weth",
    symbol: "WETH",
    decimals: 18,
    logo: WETHIcon,
  },
  ETH: {
    providerId: "ethereum",
    symbol: "ETH",
    decimals: 18,
    logo: WETHIcon,
  },
  TSETH: {
    providerId: "ethereum",
    symbol: "TSETH",
    decimals: 18,
    logo: WETHIcon,
  },
  AVAX: {
    providerId: "avalanche-2",
    symbol: "AVAX",
    decimals: 18,
  },
  MATIC: {
    providerId: "matic-network",
    symbol: "MATIC",
    decimals: 18,
  },
  OP: {
    providerId: "optimism",
    symbol: "OP",
    decimals: 18,
    logo: OPIcon,
  },
  BNB: {
    providerId: "binancecoin",
    symbol: "BNB",
    decimals: 18,
  },
  FTM: {
    providerId: "fantom",
    symbol: "FTM",
    decimals: 18,
  },
};

export type Chain = {
  id: ChainId;
  token: TokenDetails;
  label: string;
  logo?: string;
  rpcUrl: string;
};

export type ChainExplorer = {
  base: string;
  address: string;
  transaction: string;
  token: string;
  logo?: string;
  getTokenHoldersUrl: (token: string) => string;
  getSourceCode?: (address: string) => Promise<any>;
};

type ChainExplorerMap = {
  [key in ChainId]: ChainExplorer;
};

export const CHAIN_EXPLORERS: ChainExplorerMap = {
  // Ethereum Chains
  "0x1": {
    base: "https://etherscan.io/",
    address: "https://etherscan.io/address/",
    transaction: "https://etherscan.io/tx/",
    token: "https://etherscan.io/token/",
    logo: EtherscanLogo,
    getSourceCode: (address: string) => getSourceCodeFromExplorer(address),
    getTokenHoldersUrl: (token: string) =>
      `https://etherscan.io/token/${token}#balances`,
  },
  "0x4": {
    base: "https://rinkeby.etherscan.io/",
    address: "https://rinkeby.etherscan.io/address/",
    transaction: "https://rinkeby.etherscan.io/tx/",
    token: "https://rinkeby.etherscan.io/token/",
    logo: EtherscanLogo,
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        `https://api-rinkeby.etherscan.io/`,
        process.env.REACT_APP_ETHERSCAN_API_KEY
      ),
    getTokenHoldersUrl: (token: string) =>
      `https://rinkeby.io/token/${token}#balances`,
  },
  "0x5": {
    base: "https://goerli.etherscan.io/",
    address: "https://goerli.etherscan.io/address/",
    transaction: "https://goerli.etherscan.io/tx/",
    token: "https://goerli.etherscan.io/token/",
    logo: EtherscanLogo,
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        `https://api-goerli.etherscan.io/`,
        process.env.REACT_APP_ETHERSCAN_API_KEY
      ),
    getTokenHoldersUrl: (token: string) =>
      `https://goerli.io/token/${token}#balances`,
  },

  // Avalanche Chains
  "0xa86a": {
    base: "https://snowtrace.io/",
    address: "https://snowtrace.io/address/",
    transaction: "https://snowtrace.io/tx/",
    token: "https://snowtrace.io/token/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        `https://api.snowtrace.io/`,
        process.env.REACT_APP_SNOWTRACE_API_KEY
      ),
    getTokenHoldersUrl: (token: string) =>
      `https://snowtrace.io/token/${token}#balances`,
  },
  "0xa869": {
    base: "https://testnet.snowtrace.io/",
    address: "https://testnet.snowtrace.io/address/",
    transaction: "https://testnet.snowtrace.io/tx/",
    token: "https://testnet.snowtrace.io/token/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        `https://api-testnet.snowtrace.io/`,
        process.env.REACT_APP_SNOWTRACE_API_KEY
      ),
    getTokenHoldersUrl: (token: string) =>
      `https://testnet.snowtrace.io/token/${token}#balances`,
  },

  // Polygon Chains
  "0x89": {
    base: "https://polygonscan.com/",
    address: "https://polygonscan.com/address/",
    transaction: "https://polygonscan.com/tx/",
    token: "https://polygonscan.com/token/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api.polygonscan.com/",
        process.env.REACT_APP_POLYGONSCAN_API_KEY
      ),
    getTokenHoldersUrl: (token: string) =>
      `https://polygonscan.com/token/${token}#balances`,
  },
  "0x13881": {
    base: "https://mumbai.polygonscan.com/",
    address: "https://mumbai.polygonscan.com/address/",
    transaction: "https://mumbai.polygonscan.com/tx/",
    token: "https://mumbai.polygonscan.com/token/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api-testnet.polygonscan.com/",
        process.env.REACT_APP_POLYGONSCAN_API_KEY
      ),
    getTokenHoldersUrl: (token: string) =>
      `https://mumbai.polygonscan.com/token/${token}#balances`,
  },

  // Binance Smart Chain
  "0x38": {
    base: "https://bscscan.com/",
    address: "https://bscscan.com/address/",
    transaction: "https://bscscan.com/tx/",
    token: "https://bscscan.com/token/",
    logo: BSCLogo,
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api.bscscan.com/",
        process.env.REACT_APP_BSCSCAN_API_KEY
      ),
    getTokenHoldersUrl: (token: string) =>
      `https://bscscan.com/token/${token}#balances`,
  },
  "0x61": {
    base: "https://testnet.bscscan.com/",
    address: "https://testnet.bscscan.com/address/",
    transaction: "https://testnet.bscscan.com/tx/",
    token: "https://testnet.bscscan.com/token/",
    logo: BSCLogo,
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api-testnet.bscscan.com/",
        process.env.REACT_APP_BSCSCAN_API_KEY
      ),
    getTokenHoldersUrl: (token: string) =>
      `https://testnet.bscscan.com/token/${token}#balances`,
  },

  // Fantom
  "0xfa": {
    base: "https://ftmscan.com/",
    address: "https://ftmscan.com/address/",
    transaction: "https://ftmscan.com/tx/",
    token: "https://ftmscan.com/token/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api.ftmscan.com/",
        process.env.REACT_APP_FTMSCAN_API_KEY
      ),
    getTokenHoldersUrl: (token: string) =>
      `https://ftmscan.com/token/${token}#balances`,
  },
  "0xfa2": {
    base: "https://testnet.ftmscan.com/",
    address: "https://testnet.ftmscan.com/address/",
    transaction: "https://testnet.ftmscan.com/tx/",
    token: "https://testnet.ftmscan.com/token/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api-testnet.ftmscan.com/",
        process.env.REACT_APP_FTMSCAN_API_KEY
      ),
    getTokenHoldersUrl: (token: string) =>
      `https://testnet.ftmscan.com/token/${token}#balances`,
  },

  // Scroll Pre-Alpha L1 Tesetnet
  "0x8274f": {
    base: "https://l1scan.scroll.io/",
    address: "https://l1scan.scroll.io/address/",
    transaction: "https://l1scan.scroll.io/tx/",
    token: "https://l1scan.scroll.io/token/",
    getTokenHoldersUrl: (token: string) =>
      `https://l1scan.scroll.io/token/${token}/token-holders`,
  },

  // Scroll Pre-Alpha L2 Testnet
  "0x82752": {
    base: "https://l2scan.scroll.io/",
    address: "https://l2scan.scroll.io/address/",
    transaction: "https://l2scan.scroll.io/tx/",
    token: "https://l2scan.scroll.io/token/",
    getTokenHoldersUrl: (token: string) =>
      `https://l2scan.scroll.io/token/${token}/token-holders`,
  },

  // Scroll Alpha Testnet
  "0x82751": {
    base: "https://blockscout.scroll.io/",
    address: "https://blockscout.scroll.io/address/",
    transaction: "https://blockscout.scroll.io/tx/",
    token: "https://blockscout.scroll.io/token/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://blockscout.scroll.io/",
        process.env.SCROLLSCAN_L2_API_KEY
      ),
    getTokenHoldersUrl: (token: string) =>
      `https://blockscout.scroll.io/token/${token}/token-holders`,
  },

  // Arbitrum Chains
  "0xa4b1": {
    base: "https://arbiscan.io/",
    address: "https://arbiscan.io/address/",
    transaction: "https://arbiscan.io/tx/",
    token: "https://arbiscan.io/token/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api.arbiscan.io/",
        process.env.REACT_APP_ARBISCAN_API_KEY
      ),
    getTokenHoldersUrl: (token: string) =>
      `https://arbiscan.io/token/${token}#balances`,
  },
  "0x66eed": {
    base: "https://goerli.arbiscan.io/",
    address: "https://goerli.arbiscan.io/address/",
    transaction: "https://goerli.arbiscan.io/tx/",
    token: "https://goerli.arbiscan.io/token/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api-goerli.arbiscan.io/",
        process.env.REACT_APP_ARBISCAN_API_KEY
      ),
    getTokenHoldersUrl: (token: string) =>
      `https://goerli.arbiscan.io/token/${token}#balances`,
  },

  // Optimism Chains
  "0xa": {
    base: "https://optimistic.etherscan.io/",
    address: "https://optimistic.etherscan.io/address/",
    transaction: "https://optimistic.etherscan.io/tx/",
    token: "https://optimistic.etherscan.io/token/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api-optimistic.etherscan.io/",
        process.env.REACT_APP_OPTIMISTIC_ETHERSCAN_API_KEY
      ),
    getTokenHoldersUrl: (token: string) =>
      `https://optimistic.etherscan.io/token/${token}#balances`,
  },
  "0x1a4": {
    base: "https://goerli-optimism.etherscan.io/",
    address: "https://goerli-optimism.etherscan.io/address/",
    transaction: "https://goerli-optimism.etherscan.io/tx/",
    token: "https://goerli-optimism.etherscan.io/token/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api-goerli-optimism.etherscan.io/",
        process.env.REACT_APP_OPTIMISTIC_ETHERSCAN_API_KEY
      ),
    getTokenHoldersUrl: (token: string) =>
      `https://goerli-optimism.etherscan.io/token/${token}#balances`,
  },

  // Aurora Chains
  "0x4e454152": {
    base: "https://aurorascan.dev/",
    address: "https://aurorascan.dev/address/",
    transaction: "https://aurorascan.dev/tx/",
    token: "https://aurorascan.dev/token/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api.aurorascan.dev/",
        process.env.REACT_APP_AURORASCAN_API_KEY
      ),
    getTokenHoldersUrl: (token: string) =>
      `https://aurorascan.dev/token/${token}#balances`,
  },
  "0x4e454153": {
    base: "https://testnet.aurorascan.dev/",
    address: "https://testnet.aurorascan.dev/address/",
    transaction: "https://testnet.aurorascan.dev/tx/",
    token: "https://testnet.aurorascan.dev/token/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(
        address,
        "https://api-testnet.aurorascan.dev/",
        process.env.REACT_APP_AURORASCAN_API_KEY
      ),
    getTokenHoldersUrl: (token: string) =>
      `https://testnet.aurorascan.dev/token/${token}#balances`,
  },

  // zkSync Chains
  "0x144": {
    base: "https://explorer.zksync.io/",
    address: "https://explorer.zksync.io/address/",
    transaction: "https://explorer.zksync.io/tx/",
    token: "https://explorer.zksync.io/address/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(address, "https://zksync2-mainnet.zkscan.io/"),
    getTokenHoldersUrl: (token: string) =>
      `https://explorer.zksync.io/address/${token}`,
  },
  "0x118": {
    base: "https://goerli.explorer.zksync.io/",
    address: "https://goerli.explorer.zksync.io/address/",
    transaction: "https://goerli.explorer.zksync.io/tx/",
    token: "https://goerli.explorer.zksync.io/address/",
    getSourceCode: (address: string) =>
      getSourceCodeFromExplorer(address, "https://zksync2-testnet.zkscan.io/"),
    getTokenHoldersUrl: (token: string) =>
      `https://goerli.explorer.zksync.io/address/${token}`,
  },
};

export const CHAINS: ChainsMap = isMainnet
  ? {
      mainnet: {
        id: "0x1",
        token: TOKENS.ETH,
        label: "Ethereum Mainnet",
        logo: EthereumLogo,
        rpcUrl: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
      },
      avalanche: {
        id: "0xa86a",
        token: TOKENS.AVAX,
        logo: AvalancheLogo,
        label: "Avalanche",
        rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
      },
      polygon: {
        id: "0x89",
        token: TOKENS.MATIC,
        logo: PolygonLogo,
        label: "Polygon (Matic)",
        rpcUrl: "https://matic-mainnet.chainstacklabs.com",
      },
      binance: {
        id: "0x38",
        token: TOKENS.BNB,
        logo: BSCLogo,
        label: "Binance Smart Chain",
        rpcUrl: "https://bsc-dataseed.binance.org/",
      },
      fantom: {
        id: "0xfa",
        token: TOKENS.FTM,
        logo: FantomLogo,
        label: "Fantom Opera",
        rpcUrl: "https://rpcapi.fantom.network/",
      },
      arbitrumOne: {
        id: "0xa4b1",
        token: TOKENS.ETH,
        logo: ArbitrumLogo,
        label: "Arbitrum One",
        rpcUrl: "https://arb1.arbitrum.io/rpc",
      },
      optimism: {
        id: "0xa",
        token: TOKENS.ETH,
        logo: OptimismLogo,
        label: "Optimism",
        rpcUrl: "https://mainnet.optimism.io",
      },
      aurora: {
        id: "0x4e454152",
        token: TOKENS.ETH,
        logo: AuroraLogo,
        label: "Aurora",
        rpcUrl: "https://mainnet.aurora.dev",
      },
      zksyncEra: {
        id: "0x144",
        token: TOKENS.ETH,
        logo: ZkSyncLogo,
        label: "zkSync Era",
        rpcUrl: "https://mainnet.era.zksync.io",
      },
    }
  : {
      rinkeby: {
        id: "0x4",
        token: TOKENS.ETH,
        label: "Ethereum Rinkeby",
        logo: EthereumLogo,
        rpcUrl: `https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
      },
      goerli: {
        id: "0x5",
        token: TOKENS.ETH,
        label: "Ethereum Goerli",
        logo: EthereumLogo,
        rpcUrl: `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
      },
      avalancheFuji: {
        id: "0xa869",
        token: TOKENS.AVAX,
        logo: AvalancheLogo,
        label: "Avalanche FUJI C-Chain",
        rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
      },
      mumbai: {
        id: "0x13881",
        token: TOKENS.MATIC,
        logo: PolygonLogo,
        label: "Polygon Matic Mumbai",
        rpcUrl: "https://rpc-mumbai.maticvigil.com",
      },
      binanceTestnet: {
        id: "0x61",
        token: TOKENS.BNB,
        logo: BSCLogo,
        label: "Binance Smart Chain Testnet",
        rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
      },
      fantomTestnet: {
        id: "0xfa2",
        token: TOKENS.FTM,
        logo: FantomLogo,
        label: "Fantom Testnet",
        rpcUrl: "https://rpc.testnet.fantom.network/",
      },
      arbitrumGoerli: {
        id: "0x66eed",
        token: TOKENS.ETH,
        logo: ArbitrumLogo,
        label: "Arbitrum Goerli",
        rpcUrl: "https://goerli-rollup.arbitrum.io/rpc",
      },
      scrollL1Testnet: {
        id: "0x8274f",
        token: TOKENS.TSETH,
        logo: ScrollLogo,
        label: "Scroll Pre-Alpha L1 Testnet",
        rpcUrl: "https://prealpha.scroll.io/l1",
      },
      scrollL2Testnet: {
        id: "0x82752",
        token: TOKENS.TSETH,
        logo: ScrollLogo,
        label: "Scroll Pre-Alpha L2 Testnet",
        rpcUrl: "https://prealpha.scroll.io/l2",
      },
      scrollAlphaTestnet: {
        id: "0x82751",
        token: TOKENS.TSETH,
        logo: ScrollLogo,
        label: "Scroll Alpha Testnet",
        rpcUrl: "https://alpha-rpc.scroll.io/l2",
      },
      optimismGoerli: {
        id: "0x1a4",
        token: TOKENS.ETH,
        logo: OptimismLogo,
        label: "Optimism Goerli",
        rpcUrl: "https://goerli.optimism.io",
      },
      aurora: {
        id: "0x4e454153",
        token: TOKENS.ETH,
        logo: AuroraLogo,
        label: "Aurora Testnet",
        rpcUrl: "https://testnet.aurora.dev",
      },
      zksyncGoerli: {
        id: "0x118",
        token: TOKENS.ETH,
        logo: ZkSyncLogo,
        label: "zkSync Era Goerli",
        rpcUrl: "https://testnet.era.zksync.dev",
      },
    };

export const DEFAULT_CHAIN = isMainnet ? CHAINS.mainnet : CHAINS.goerli;

export const CHAIN_IDS = Object.values(CHAINS).map((chain) => chain.id);

export type ChainId =
  | "0x1"
  | "0x4"
  | "0x5"
  | "0xa86a"
  | "0xa869"
  | "0x89"
  | "0x13881"
  | "0x38"
  | "0x61"
  | "0xfa"
  | "0xfa2"
  | "0x8274f"
  | "0x82751"
  | "0x82752"
  | "0xa4b1"
  | "0x66eed"
  | "0xa"
  | "0x1a4"
  | "0x4e454152"
  | "0x4e454153"
  | "0x144"
  | "0x118";

const chains = isMainnet
  ? [
      Network.mainnet,
      Network.avalanche,
      Network.polygon,
      Network.binance,
      Network.fantom,
      Network.arbitrumOne,
      Network.optimism,
      Network.aurora,
      Network.zksyncEra,
    ]
  : [
      Network.rinkeby,
      Network.goerli,
      Network.avalancheFuji,
      Network.mumbai,
      Network.binanceTestnet,
      Network.fantomTestnet,
      Network.arbitrumGoerli,
      Network.scrollL1Testnet,
      Network.scrollL2Testnet,
      Network.scrollAlphaTestnet,
      Network.optimismGoerli,
      Network.auroraTestnet,
      Network.zksyncGoerli,
    ];

export type Chains = typeof chains[number];

export type ChainsMap = {
  [key in Chains]?: Chain;
};

type SupportedCurrencies = {
  [key: string]: Currency;
};

export const SUPPORTED_CURRENCIES: SupportedCurrencies = {
  usd: {
    code: "usd",
    symbol: "$",
  },
};

export const supportedCurrenciesCodes = Object.values(SUPPORTED_CURRENCIES).map(
  (currency) => currency.code
);
export type SupportedCurencyCode = typeof supportedCurrenciesCodes[number];

export const BLACKLISTED_ADDRESSES = [].map((address) => address.toLowerCase());

/**
 * A map between token addresses and Coingecko IDs
 * This mapping is chain dependent.
 */
export const TOKEN_ADDRESSES: TokenPriceProviderMap = {
  "0x1": {
    "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48": TOKENS.USDC,
    "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2": TOKENS.WETH,
  },
  "0x5": {
    "0x07865c6E87B9F70255377e024ace6630C1Eaa37F": TOKENS.USDC,
    "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6": TOKENS.WETH,
  },
  "0x1a4": {
    "0x4200000000000000000000000000000000000006": TOKENS.WETH,
    "0x4200000000000000000000000000000000000042": TOKENS.OP,
    "0xc5D8d1002A9674E41942e3eaeaC41afD74fD557a": TOKENS.USDC,
  },
  "0xa": {
    "0x4200000000000000000000000000000000000006": TOKENS.WETH,
    "0x4200000000000000000000000000000000000042": TOKENS.OP,
    "0x7F5c764cBc14f9669B88837ca1490cCa17c31607": TOKENS.USDC,
  },
  "0x82751": {
    "0xdAA6Fa39bf54dbf9C6666Aaf1547906B3f55Ce6D": TOKENS.WETH,
  },
  "0x82752": {
    "0xdAA6Fa39bf54dbf9C6666Aaf1547906B3f55Ce6D": TOKENS.WETH,
  },
  "0x66eed": {
    "0xEe01c0CD76354C383B8c7B4e65EA88D00B06f36f": TOKENS.WETH,
  },
  "0xa4b1": {
    "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1": TOKENS.WETH,
  },
  "0x118": {
    "0x0faF6df7054946141266420b43783387A78d82A9": TOKENS.USDC,
    "0x20b28B1e4665FFf290650586ad76E977EAb90c5D": TOKENS.WETH,
  },
  "0x144": {
    "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91": TOKENS.WETH,
  },
};

export const getToken = (
  chainId: string,
  tokenId: null | undefined | Address
): TokenDetails | undefined => {
  const token = ["null", null, undefined, ""].includes(tokenId)
    ? getNetworkById(chainId)?.token
    : TOKEN_ADDRESSES[chainId]?.[tokenId];

  if (!token) {
    console.warn(
      `The app does not support this token. Displayed values might be misrepresented.`,
      chainId,
      tokenId
    );
  }

  return token;
};

export const getTokenAddress = (
  chainId: string,
  tokenSymbol: string
): string | undefined => {
  if (!TOKEN_ADDRESSES[chainId]) return undefined;
  for (const tokenAddress of Object.keys(TOKEN_ADDRESSES[chainId])) {
    if (TOKEN_ADDRESSES[chainId]?.[tokenAddress]?.symbol === tokenSymbol) {
      return tokenAddress;
    }
  }

  return undefined;
};
export const dateFormat = "MM/dd/y";
export const timeFormat = "h:mmaaa";
export const dateTimeFormat = `${dateFormat} ${timeFormat}`;
export const POOL_CONFIG = {
  challengerPayout: {
    min: 2,
    max: 20,
  },
  decayRate: {
    min: 5,
    max: 600,
  },
  testAuthorRewardRate: {
    min: 0,
    max: 10,
  },
};
