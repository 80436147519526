const colors = {
  white: "#ffffff",
  primaryBlue: "#2196F3",
  primaryBlueLite: "#3BB0FF",
  purple10: "#f1eff7",
  unverifiedOrange: "#d47a42",
  greyscale: "#555555",
  greyscaleSecondary: "rgba(119, 119, 119, 1)",
  grey: "rgba(196, 196, 196, 1)",
  grey100: "#f5f5f5",
  highEmphasis: "rgba(0, 0, 0, 0.87)",
  mediumEmphasis: "rgba(0, 0, 0, 0.6)",
  // new branding colors
  stakeBlue: "rgb(0, 100, 214)",
  challengePink: "rgb(222, 69, 142)",
  statPurple: "rgba(114, 91, 176, 1)",
  statPurple15: "rgba(114, 91, 176, 0.15)",
  linkBlue: "rgba(55, 144, 176, 1)",
  linkBlue60: "rgba(55, 144, 176, .6)",
  linkBlue23: "rgba(55, 144, 176, .23)",
  linkBlue10: "rgba(55, 144, 176, .1)",
  failedRed: "rgba(218, 22, 12, 1)",
  failedRed10: "rgba(218, 22, 12, 0.1)",
  failedText: "rgba(176, 0, 32, 1)",
  black77: "rgba(0, 0, 0, 0.77)",
  black87: "rgba(0, 0, 0, 0.87)",
  black69: "rgba(0, 0, 0, 0.69)",
  black54: "rgba(0, 0, 0, 0.54)",
  black42: "rgba(0, 0, 0, 0.42)",
  black23: "rgba(0, 0, 0, 0.23)",
  black5: "rgba(0, 0, 0, 0.05)",
  warningYellow: "rgba(222, 176, 69, 1)",
  warningYellow23: "rgba(222, 176, 69, 0.23)",
  warningSecondaryYellow: "rgb(250 243 227)",
  newYellow: "rgba(222, 196, 51, 0.65)",
  noticeYellow: "rgb(250, 245, 227)",
  warningRed: "rgba(218, 22, 12, 1)",
  warningRed10: "rgba(218, 22, 12, 0.1)",
  success: "#37B03D",
  danger: "#DA160C",
  warning: "#DEB045",
  unavailable: "#949494",
};

export default colors;
