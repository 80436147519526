import {
  useSnackbar,
  OptionsObject,
  WithSnackbarProps,
  SnackbarKey,
} from "notistack";

let useSnackbarRef: WithSnackbarProps;
export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

const SnackbarUtils = {
  success(msg: string, options?: OptionsObject) {
    this.toast(msg, { ...options, variant: "success" });
  },
  warning(msg: string, options?: OptionsObject) {
    this.toast(msg, { ...options, variant: "warning" });
  },
  info(msg: string, options?: OptionsObject) {
    this.toast(msg, { ...options, variant: "info" });
  },
  error(msg: string, options?: OptionsObject) {
    this.toast(msg, { ...options, variant: "error" });
  },
  toast(msg: string, options: OptionsObject) {
    useSnackbarRef.enqueueSnackbar(msg, options);
  },
  close(key: SnackbarKey) {
    useSnackbarRef.closeSnackbar(key);
  },
};

export default SnackbarUtils;
