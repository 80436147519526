import React, { useCallback, useEffect, useState } from "react";
import { getNetworkById, getSupportedChains } from "./utils";
import {
  sendEvent,
  AnalyticsEvents,
  setUserAccount,
} from "analytics/analytics";
import {
  initOnboard,
  ONBOARD_CHAINS,
  web3Onboard,
} from "utils/onboard/onboardUtils";
import { ConnectOptions, WalletState } from "@web3-onboard/core";
import { Storage } from "./storage";
import { ChainId, TokenDetails } from "./constants";
import { ethers } from "ethers";
import { getAddress } from "ethers/lib/utils";
import { app, getUserData } from "db/graphql-query-resolver";

export interface WalletBalance {
  formattedString: string;
  number: number;
}

export interface WalletData {
  connectedWallet?: WalletState;
  account?: string;
  networkId?: ChainId;
  networkName?: string;
  token?: TokenDetails;
  balance?: WalletBalance;
  provider?: any;
  ethersProvider?: ethers.providers.Web3Provider;
  isNetworkSupported: boolean;
}

export interface Wallet extends WalletData {
  connect: VoidFunction;
  disconnect: VoidFunction;
  connecting: boolean;
  refreshBalance: VoidFunction;
  setChain: (chainId: string) => void;
}

const initialWalletContext: Wallet = {
  account: undefined,
  networkId: undefined,
  networkName: undefined,
  token: undefined,
  connect: () => undefined,
  disconnect: () => undefined,
  refreshBalance: () => undefined,
  setChain: () => undefined,
  connecting: false,
  provider: undefined,
  ethersProvider: undefined,
  isNetworkSupported: false,
};

const WalletContext = React.createContext<Wallet>(initialWalletContext);

export function useWallet(): Wallet {
  return React.useContext(WalletContext);
}

let lastAttachedAddress = undefined;

const OnboardWallet: React.FunctionComponent = (props) => {
  if (!web3Onboard) {
    initOnboard();
  }

  const [connectedWallet, setConnectedWallet] = useState<WalletState | null>(
    undefined
  );
  const [connecting, setConnecting] = useState(false);
  const [formattedBalance, setFormattedBalance] = useState<
    WalletBalance | undefined
  >({
    formattedString: "0",
    number: 0,
  });

  const connect = React.useCallback(async (options?: ConnectOptions) => {
    setConnecting(true);

    await web3Onboard.connectWallet(options);

    setConnecting(false);
  }, []);

  const disconnect = React.useCallback(async () => {
    setConnecting(true);

    await web3Onboard.disconnectWallet({ label: connectedWallet?.label });

    setConnecting(false);
  }, [connectedWallet?.label]);

  const setChain = React.useCallback(
    async (chainId: string) => {
      if (!connectedWallet) return;

      await web3Onboard.setChain({
        chainId: chainId,
        wallet: connectedWallet?.label,
      });
    },
    [connectedWallet]
  );

  const refreshBalance = useCallback(async () => {
    await web3Onboard.state.actions.updateBalances();
  }, []);

  useEffect(() => {
    const formatBalance = (balance?: string): WalletBalance | undefined => {
      if (typeof balance === "undefined" || balance === null)
        return {
          formattedString: "0",
          number: 0,
        };

      return {
        formattedString: Number(balance).toFixed(3),
        number: Number(balance) * 1e18,
      };
    };

    const walletsSub = web3Onboard.state.select("wallets");
    const subscription = walletsSub.subscribe(async (wallets) => {
      let wallet = wallets?.[0];
      const chainId = wallet?.chains?.[0]?.id;

      const connectedAddress = wallet?.accounts?.[0]?.address;
      if (
        connectedAddress &&
        connectedAddress !== lastAttachedAddress &&
        !getUserData()?.metadata?.addresses?.[connectedAddress]
      ) {
        lastAttachedAddress = connectedAddress;

        await app.currentUser?.callFunction("attachAddress", connectedAddress);
        await app.currentUser?.refreshCustomData();
      }

      setConnectedWallet(wallet);
      setFormattedBalance(
        formatBalance(
          wallet?.accounts?.[0].balance?.[ONBOARD_CHAINS[chainId].token]
        )
      );
      Storage.setConnectedWallet(wallet?.label);
    });

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const previouslyConnectedWallet = Storage.getConnectedWallet();

    if (previouslyConnectedWallet) {
      // Connect "silently" and disable all onboard modals to avoid them flashing on page load
      connect({
        autoSelect: { label: previouslyConnectedWallet, disableModals: true },
      });
    }
  }, [connect]);

  useEffect(() => {
    const account = connectedWallet?.accounts?.[0].address;
    if (account) {
      setUserAccount(account);
      sendEvent(AnalyticsEvents.walletConnected, { account: account });
    }
  }, [connectedWallet?.accounts]);

  const value = React.useMemo<Wallet>(() => {
    const networkId = connectedWallet?.chains?.[0]?.id;
    const address = connectedWallet?.accounts?.[0]?.address
      ? getAddress(connectedWallet?.accounts?.[0]?.address)
      : undefined;

    return {
      connectedWallet: connectedWallet,
      account: address,
      networkId: networkId as ChainId,
      networkName: networkId ? getNetworkById(networkId)?.label : undefined,
      token: getNetworkById(networkId)?.token,
      isNetworkSupported:
        address &&
        Object.values(getSupportedChains()).some(
          (chain) => chain.id.toLowerCase() === networkId.toLowerCase()
        ),
      provider: connectedWallet?.provider,
      ethersProvider: connectedWallet
        ? new ethers.providers.Web3Provider(connectedWallet?.provider)
        : undefined,
      connect,
      disconnect,
      refreshBalance,
      balance: formattedBalance,
      connecting,
      setChain,
    };
  }, [
    connectedWallet,
    connect,
    disconnect,
    connecting,
    formattedBalance,
    refreshBalance,
    setChain,
  ]);

  return (
    <WalletContext.Provider value={value}>
      {props.children}
    </WalletContext.Provider>
  );
};

const Web3WalletProvider: React.FunctionComponent = (props) => {
  return <OnboardWallet>{props.children}</OnboardWallet>;
};

export default Web3WalletProvider;
