import { makeStyles } from "@material-ui/core/styles";

import colors from "style/colors";

import Hidden from "@material-ui/core/Hidden";

import AboutAnteIcon from "assets/img/about-ante.svg";
import DiscordIcon from "assets/img/discord-chat.svg";
import TwitterIcon from "assets/img/twitter.svg";
import MediumIcon from "assets/img/medium.svg";
import MirrorIcon from "assets/img/mirror.svg";
import DocsIcon from "assets/img/docs.svg";
import CoreGitHubIcon from "assets/img/code-core.svg";
import ResponsibleDisclosureIcon from "assets/img/responsible-disclosure.svg";
import FeedbackIcon from "assets/img/feedback.svg";
import PrivacyTermsIcon from "assets/img/privacy-terms.svg";
import { Link } from "react-router-dom";

const moreLinkStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    color: colors.linkBlue,
    fontSize: "18px",
    textTransform: "none",
    lineHeight: "28px",
    minHeight: "48px",
    margin: "16px 16px",
    justifyContent: "left",
    alignItems: "center",
    fontWeight: 400,
    opacity: 1,
    cursor: "pointer",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      marginBottom: "0px",
    },
    "&:hover": {
      "& $links": {
        display: "flex",
      },
    },
  },
  heading: {
    color: colors.black69,
    position: "relative",
    marginBottom: "8px",
  },
  leftSpacing: {
    marginLeft: "4px",
  },
  links: {
    color: colors.black69,
    fontSize: "14px",
    display: "none",
    position: "absolute",
    bottom: "-6px",
    left: "-12px",
    transform: "translateY(100%)",
    flexDirection: "column",
    background: "white",
    padding: "12px",
    whiteSpace: "nowrap",
    "& a": {
      display: "inline-flex",
    },
    "& a:hover": {
      color: colors.linkBlue,
      textDecoration: "underline",
    },
    "& a img": {
      marginRight: "8px",
      width: "16px",
    },
  },
  linksMobile: {
    color: colors.black69,
    fontSize: "14px",
    display: "block",
    listStyleType: "none",
    minHeight: "108px",
    "& li": {
      height: "36px",
    },
    "& li:hover": {
      color: colors.linkBlue,
      textDecoration: "underline",
    },
    "& li a": {
      display: "inline-flex",
    },
    "& li a img": {
      marginRight: "8px",
      width: "16px",
    },
  },
  link: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const links = [
  {
    imgSrc: AboutAnteIcon,
    label: "About Ante",
    url: "https://www.ante.finance",
    target: "_blank",
  },
  {
    imgSrc: DocsIcon,
    label: "Documentation",
    url: "https://docs.ante.finance/ante-v0.6",
    target: "_blank",
  },
  {
    imgSrc: CoreGitHubIcon,
    label: "GitHub",
    url: "https://github.com/antefinance/ante-v0-core",
    target: "_blank",
  },
  {
    imgSrc: DiscordIcon,
    label: "Discord",
    url: "https://discord.gg/yaJthzNdNG",
    target: "_blank",
  },
  {
    imgSrc: TwitterIcon,
    label: "Twitter",
    url: "https://twitter.com/antefinance",
    target: "_blank",
  },
  {
    imgSrc: MediumIcon,
    label: "Medium",
    url: "https://medium.com/ante-finance",
    target: "_blank",
  },
  {
    imgSrc: MirrorIcon,
    label: "Mirror",
    url: "https://mirror.xyz/antefinance.eth",
    target: "_blank",
  },
  {
    imgSrc: ResponsibleDisclosureIcon,
    label: "Responsible Disclosure",
    url: "mailto:security@ante.finance?subject=Responsible%20Bug%20Disclosure",
    target: "_blank",
  },
  {
    imgSrc: FeedbackIcon,
    label: "Submit Feedback",
    url: "https://ante.finance/feedback",
    target: "_blank",
  },
  {
    imgSrc: PrivacyTermsIcon,
    url: "/disclaimer",
    label: "Disclaimer",
  },
];

const MoreLinks = ({ onMenuClose }: { onMenuClose: () => void }) => {
  const styles = moreLinkStyles();

  const renderLinks = () => {
    return links.map((link) => (
      <li key={link.url}>
        {link.target !== undefined ? (
          <a href={link.url} target={link.target}>
            <img src={link.imgSrc} alt={link.url} /> {link.label}
          </a>
        ) : (
          <Link to={link.url} onClick={onMenuClose}>
            <img src={link.imgSrc} alt={link.url} /> {link.label}
          </Link>
        )}
      </li>
    ));
  };

  return (
    <div className={styles.root}>
      <Hidden smDown implementation="css">
        <div className={styles.linksMobile}>{renderLinks()}</div>
      </Hidden>
      <Hidden mdUp implementation="css">
        <div className={styles.heading}>More</div>
        <div className={styles.linksMobile}>{renderLinks()}</div>
      </Hidden>
    </div>
  );
};

export default MoreLinks;
