import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import "typeface-poppins";
import colors from "./colors";

const fonts = [
  "Poppins",
  "Roboto",
  '"Segoe UI"',
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(",");

const breakpoints = createBreakpoints({});

export default createTheme({
  typography: {
    fontFamily: fonts,
    h1: { fontFamily: fonts },
    h2: { fontFamily: fonts },
    h3: { fontFamily: fonts },
    h4: { fontFamily: fonts },
    h5: { fontFamily: fonts },
    h6: { fontFamily: fonts },
    subtitle1: { fontFamily: fonts },
    subtitle2: { fontFamily: fonts },
    body1: { fontFamily: fonts },
    body2: { fontFamily: fonts },
    button: { fontFamily: fonts },
    caption: {
      fontFamily: fonts,
      color: colors.black42,
    },
    overline: { fontFamily: fonts },
  },
  palette: {
    primary: {
      main: "#2196F3",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 864,
      lg: 1112,
      xl: 1366,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        a: {
          color: colors.linkBlue,
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "inherit" as const,
        fontSize: "1rem",
        fontWeight: 600,
      },
    },
    MuiSwitch: {
      switchBase: {
        color: "#fff",
      },
      colorSecondary: {
        "&$checked": {
          // Controls checked color for the thumb
          color: colors.primaryBlue,
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: "#000",
        "$checked$checked + &": {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: colors.primaryBlue,
        },
      },
    },
    MuiCardContent: {
      root: {
        [breakpoints.down("md")]: {
          padding: "0.75rem",
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontWeight: 400,
        fontSize: ".75rem",
        padding: "1rem",
        background: "#fff",
        backgroundColor: colors.noticeYellow,
        color: "#000",
      },
      arrow: {
        color: colors.noticeYellow,
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: colors.primaryBlue,
        "&$checked": {
          color: colors.primaryBlue,
        },
      },
    },
    // @ts-ignore
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiOutlinedInput-root"] $input': {
          padding: 0,
        },
      },
    },
  },
});
