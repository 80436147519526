import { makeStyles } from "@material-ui/core/styles";

import { useWallet } from "utils/wallet";
import colors from "style/colors";
import { Chain, ChainId } from "utils/constants";
import { SelectNetworkModal } from "components/SelectNetworkModal";
import { useState } from "react";
import { shadows } from "style/shadows";
import AnteButton from "components/AnteButton/AnteButton";

const useStyles = makeStyles({
  connectWalletButton: {
    color: colors.linkBlue,
    textTransform: "none",
    fontSize: "16px",
    border: `1px solid ${colors.linkBlue}`,
    outline: 0,
    backgroundColor: colors.white,
    borderRadius: "0.5rem",
    padding: "0.5rem 1rem",
    fontWeight: 600,
    boxShadow: shadows[0],
    lineHeight: "1.5rem",
    "&:hover": {
      opacity: 0.8,
      cursor: "pointer",
    },
  },
});

type ConnectWalletButtonProps = {
  chain?: Chain;
  allowedChains?: ChainId[];
  text?: string;
  unsupportedText?: string;
  onClick?: () => void;
};

const ConnectWalletButton = ({
  chain,
  allowedChains,
  text,
  unsupportedText,
  onClick,
}: ConnectWalletButtonProps) => {
  const { account, connect, isNetworkSupported, networkId, setChain } =
    useWallet();
  const styles = useStyles();
  const [isShown, setIsShown] = useState<boolean>(false);

  const hideNetworkSelector = () => {
    setIsShown(false);
  };

  if (account && !isNetworkSupported) {
    return (
      <>
        <AnteButton
          variant="primary2"
          onClick={() => {
            if (onClick) {
              onClick();
            }
            setIsShown(true);
          }}
          className={styles.connectWalletButton}
          data-testid="connect-wallet-button"
        >
          {unsupportedText ? unsupportedText : "Unsupported network"}
        </AnteButton>
        <SelectNetworkModal isShown={isShown} hide={hideNetworkSelector} />
      </>
    );
  }

  if (account && allowedChains && !allowedChains.includes(networkId)) {
    return (
      <>
        <AnteButton
          variant="primary2"
          onClick={() => {
            if (onClick) {
              onClick();
            }
            setIsShown(true);
          }}
          className={styles.connectWalletButton}
          data-testid="connect-wallet-button"
        >
          {unsupportedText ? unsupportedText : "Unsupported network"}
        </AnteButton>
        <SelectNetworkModal
          allowedChains={allowedChains}
          isShown={isShown}
          hide={hideNetworkSelector}
        />
      </>
    );
  }

  if (account && chain && networkId !== chain?.id) {
    return (
      <AnteButton
        variant="primary2"
        onClick={() => {
          if (onClick) {
            onClick();
          }
          setChain(chain.id);
        }}
        className={styles.connectWalletButton}
        data-testid="connect-wallet-button"
      >
        {unsupportedText ? unsupportedText : `Switch to ${chain.label}`}
      </AnteButton>
    );
  }

  return (
    <AnteButton
      variant="primary2"
      onClick={() => {
        if (onClick) {
          onClick();
        }
        connect();
      }}
      className={styles.connectWalletButton}
      data-testid="connect-wallet-button"
    >
      {text ? text : "Connect to wallet"}
    </AnteButton>
  );
};

export default ConnectWalletButton;
