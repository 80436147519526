import { makeStyles } from "@material-ui/core";
import colors from "style/colors";

export const useStyles = makeStyles({
  root: {
    textTransform: "none",
    fontSize: "16px",
    outline: 0,
    borderRadius: "0.5rem",
    padding: "0.5rem 1rem",
    fontWeight: 600,
    lineHeight: "1.5rem",
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    justifyContent: "center",
    textDecoration: "none",
    "&:hover": {
      opacity: 0.8,
      cursor: "pointer",
      textDecoration: "none",
    },
    "&:disabled": {
      opacity: 1,
      cursor: "inherit",
      color: colors.black42,
      border: `1px solid ${colors.black23}`,
      backgroundColor: colors.black5,
    },
  },
  primary1: {
    color: colors.white,
    border: `1px solid ${colors.linkBlue}`,
    backgroundColor: colors.linkBlue,
  },
  primary2: {
    color: colors.linkBlue,
    border: `1px solid ${colors.linkBlue}`,
    backgroundColor: colors.white,
  },
  secondary1: {
    color: colors.linkBlue,
    border: `1px solid ${colors.black5}`,
    backgroundColor: colors.black5,
  },
  text: {
    color: colors.linkBlue,
    border: 0,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: colors.linkBlue10,
    },
    "&:disabled": {
      opacity: 1,
      cursor: "inherit",
      color: colors.black42,
      border: 0,
      backgroundColor: "inherit",
    },
  },
  dangerText: {
    color: colors.failedRed,
    border: 0,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: colors.failedRed10,
    },
  },
  small: {
    fontSize: "0.75rem",
    lineHeight: "1.125rem",
    padding: "0.375rem 1rem",
  },
  fullWidth: {
    width: "100%",
  },
});
