import * as amplitude from "@amplitude/analytics-browser";

amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);

export interface AnalyticsEvent {
  eventName: AnalyticsEvents | AnteGenEvents;
  options?: any;
}

export const sendEvent = (eventName: string, options: any = {}) => {
  amplitude.track(eventName, {
    ...options,
    version: process.env.REACT_APP_VERSION,
  });
};

export const setIdentify = (key: string, value: string) => {
  const identify = new amplitude.Identify().set(key, value);
  amplitude.identify(identify);
};

export const unsetIdentify = (key: string) => {
  const identify = new amplitude.Identify().unset(key);
  amplitude.identify(identify);
};

const USER_ACCOUNT_KEY = "account";

export const setUserAccount = (value: string) => {
  setIdentify(USER_ACCOUNT_KEY, value);
};

export const unsetUserAccount = () => {
  unsetIdentify(USER_ACCOUNT_KEY);
};

export enum AnalyticsEvents {
  pageView = "page_view",
  projectTrustTabView = "page_project_trustTabView",
  walletConnected = "wallet_connected",
  stake = "transaction_stake",
  extendStakeLock = "transaction_extendStakeLock",
  challenge = "transaction_challenge",
  registerChallenge = "transaction_registerChallenge",
  confirmChallenge = "transaction_confirmChallenge",
  withdrawChallenge = "transaction_withdrawChallenge",
  claim = "transaction_claimReward",
  claimReward = "transaction_claimAuthorReward",
  verifyTest = "transaction_verifyTest",
  initiateUnstake = "transaction_initiateUnstake",
  withdrawStake = "transaction_withdrawStake",
  navTabClicked = "navigation_tabClick",
  footerLinkClicked = "footer_linkClick",
  createPoolSubmitted = "transaction_createPool",
  cancelWithdraw = "transaction_cancelWithdraw",
}

export enum InputEvents {
  clickHelper = "input_clickHelper",
}

export enum AnteGenEvents {
  testTypeSelected = "antegen_selectTestType",
  openDeployModal = "antegen_openDeployModal",
  connectWallet = "antegen_connectWallet",
  deployTest = "antegen_deployTest",
  deployTestSuccess = "antegen_deployTestSuccess",
  deployPool = "antegen_deployPool",
  deployPoolSuccess = "antegen_deployPoolSuccess",
  closeDeployModal = "antegen_closeDeployModal",
  generateSourceCode = "antegen_generateSourceCode",
  viewHoldersList = "antegen_viewHoldersList",
  selectAutocompleteOption = "antegen_selectAutocompleteOption",
  copyCode = "antegen_copyCode",
  downloadCode = "antegen_downloadCode",
}
