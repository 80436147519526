import React from "react";
import { isMobile } from "react-device-detect";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import "./index.css";
import App from "./App";
import Providers from "./Providers";
import reportWebVitals from "./reportWebVitals";
import { ErrorPage } from "pages/ErrorPage";
import { Helmet } from "react-helmet-async";
import { Provider, ErrorBoundary } from "@rollbar/react";
import Rollbar, { Configuration } from "rollbar";

const GOOGLE_ANALYTICS_ID: string | undefined =
  process.env.REACT_APP_V06_GOOGLE_ANALYTICS_ID;
if (typeof GOOGLE_ANALYTICS_ID === "string") {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);
  ReactGA.set({
    customBrowserType: !isMobile
      ? "desktop"
      : "web3" in window || "ethereum" in window
      ? "mobileWeb3"
      : "mobileRegular",
  });
} else {
  ReactGA.initialize("test", { testMode: true });
}

const rollbarConfig: Configuration = {
  environment: process.env.REACT_APP_ENV,
  enabled: ["mainnet", "testnet", "development"].includes(
    process.env.REACT_APP_ENV
  ),
  accessToken: process.env.REACT_APP_ROLLBAR_CLIENT_ITEM_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
      },
    },
  },
};
window.rollbar = new Rollbar(rollbarConfig);

const loader = document.querySelector(".app-loader");
const removeLoader = () => loader.remove();

ReactDOM.render(
  <React.StrictMode>
    <Provider config={rollbarConfig}>
      <ErrorBoundary fallbackUI={ErrorPage}>
        <Providers>
          <Helmet>
            <title>Ante WebApp</title>
            <meta name="description" content="Ante Protocol v0.6" />
            <meta property="og:description" content="Ante Protocol v0.6" />
          </Helmet>
          <App hideLoader={removeLoader} />
        </Providers>
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
