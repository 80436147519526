import { makeStyles } from "@material-ui/core/styles";
import { AnalyticsEvents, sendEvent } from "analytics/analytics";
import ExternalLink from "components/ExternalLink/ExternalLink";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: "16px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  col: {
    margin: "5px",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
}));

function Footer() {
  const styles = useStyles();

  const handleClick = () => {
    sendEvent(AnalyticsEvents.footerLinkClicked, {
      href: "https://ante.finance/feedback",
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.col}>
        <ExternalLink
          href="https://ante.finance/feedback"
          handleOnClick={handleClick}
        >
          Submit Feedback
        </ExternalLink>
      </div>
    </div>
  );
}

export default Footer;
