import { makeStyles } from "@material-ui/core";
import GearBg from "assets/img/gearwave.png";

export const useStyles = makeStyles((theme) => ({
  root: {
    background: `center / cover no-repeat url(${GearBg})`,
    backgroundAttachment: "fixed",
  },
  container: {
    minHeight: "calc(100vh - 132px)",
    padding: "0 24px",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
}));
