import { ChainId as SDKChainId, Network } from "@antefinance/ante-sdk";
import { BigNumber } from "@ethersproject/bignumber";
import { WeiPerEther } from "@ethersproject/constants";
import { formatUnits } from "@ethersproject/units";
import { TokenPrice } from "hooks/useFetchTokenPrice";
import {
  Address,
  AnteNumber,
  ChainTokenAmount,
  KeyValue,
  PartialRecord,
  Pool,
  PoolDB,
  PoolV06,
  Rating,
  TokenAmount,
} from "types";
import { Currency } from "types/Currency";
import {
  Chain,
  getToken,
  NEW_THRESHOLD,
  ChainId,
  CHAINS,
  SUPPORTED_CURRENCIES,
  TokenDetails,
  TokenId,
  ChainsMap,
} from "./constants";

export function shortenString(str: string, chars: number = 4): string {
  return `${str.slice(0, chars + 2)}...${str.slice(-chars)}`;
}

export function getNetworkById(chainId: string): Chain | undefined {
  return Object.values(CHAINS).find(
    (chain) => chain.id.toLowerCase() === chainId?.toLowerCase()
  );
}

export function getNetworkAliasById(networkId: string): Network | undefined {
  return Network[SDKChainId[parseInt(networkId, 16)]];
}

export function getTokenByChain(chainId: string): TokenDetails | undefined {
  return getNetworkById(chainId)?.token;
}

export const newPoolDateThreshold = (): number => {
  return new Date().getTime() - NEW_THRESHOLD;
};

export function bnToStringPrecision(
  value: BigNumber,
  decimals: number,
  precision: number = 3
): string | undefined {
  try {
    return toLocaleNumber(Number(formatUnits(value, decimals)), precision);
  } catch (e) {
    return "N/A";
  }
}

export const capitalize = (str: string) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const getRating = (
  tvlInEth: number,
  trustScore: number,
  stakedByProtocol: number
): Rating => {
  let rating: Rating = "NR";
  if (tvlInEth >= 100 && trustScore >= 90) {
    rating = "S";
  } else if (tvlInEth >= 30 && trustScore >= 85) {
    rating = "AA";
  } else if (
    trustScore >= 80 &&
    (tvlInEth >= 10 || (tvlInEth >= 5 && stakedByProtocol > 0))
  ) {
    rating = "A";
  } else if (tvlInEth >= 1 && trustScore >= 75) {
    rating = "B";
  }
  return rating;
};

export const toLocaleNumber = (
  value?: number,
  precision: number = 2
): string => {
  if (value === undefined || value == null || isNaN(value)) return "-";
  return value.toLocaleString("en-US", {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
};

export const toHex = (number?: number): string | undefined => {
  return number === undefined ? undefined : `0x${number.toString(16)}`;
};

export const formatTokenValue = (value?: string, token?: string): string => {
  if (token === undefined) {
    return "-";
  }

  return `${value} ${token}`;
};

export const formatBNFiatValue = (
  value: BigNumber,
  decimals: number,
  isSuffix: boolean = false,
  useSymbol: boolean = true
): string => {
  return formatFiatValue(
    bnToStringPrecision(value, decimals, 2),
    isSuffix,
    useSymbol
  );
};

export const formatFiatValue = (
  value: string,
  isSuffix: boolean = true,
  useSymbol: boolean = true
): string => {
  const currency = SUPPORTED_CURRENCIES.usd;
  const displayedCurrency = useSymbol ? currency.symbol : currency.code;

  return isSuffix
    ? `${displayedCurrency}${value}`
    : `${value} ${displayedCurrency}`;
};

export const convertTokenToFiat = (
  token: TokenDetails,
  tokenValue: number,
  prices?: TokenPrice
): number => {
  if (!prices) {
    return NaN;
  }

  return tokenValue * getTokenPrice(token, prices, SUPPORTED_CURRENCIES.usd);
};

export const convertBNTokenToBNFiat = (
  token: TokenDetails,
  tokenValue: BigNumber,
  prices?: TokenPrice
): BigNumber | undefined => {
  if (!prices) {
    return undefined;
  }

  return tokenValue.mul(
    Math.round(getTokenPrice(token, prices, SUPPORTED_CURRENCIES.usd))
  );
};

export const convertTokenToCurrency = (
  token: TokenDetails,
  fromTokenValue: number,
  toCurrency: Currency,
  prices?: TokenPrice
): number => {
  if (!prices) {
    return NaN;
  }

  return fromTokenValue * getTokenPrice(token, prices, toCurrency);
};

export const convertCurrencyToToken = (
  currency: Currency,
  fromCurrencyValue: number,
  toToken: TokenDetails,
  prices?: TokenPrice
): number => {
  if (!prices) {
    return NaN;
  }

  return (fromCurrencyValue * 1) / getTokenPrice(toToken, prices, currency);
};

const getTokenPrice = (
  token: TokenDetails,
  prices?: TokenPrice,
  currency: Currency = SUPPORTED_CURRENCIES.usd
): number => {
  if (
    !token ||
    prices === undefined ||
    !prices.hasOwnProperty(token.providerId) ||
    !prices[token.providerId].hasOwnProperty(currency.code.toLowerCase())
  ) {
    return NaN;
  }

  return prices[token.providerId][currency.code.toLowerCase()];
};

export const ALTERNATE_PROJECT_NAMES = {
  Aave: "AAVE",
  arbitrum: "Arbitrum",
  ETH: "Ethereum",
  ETH2: "Ethereum",
  dai: "DAI",
  USDC: "USD Coin",
  USDT: "Tether",
};

export const getSoftCap = (pool: Pool): BigNumber => {
  if (pool.version < "v0.6") {
    return pool.minChallengerStake === undefined
      ? WeiPerEther.mul(5000)
      : pool.minChallengerStake.bn.mul(5000);
  }

  return pool.minSupporterStake === undefined
    ? WeiPerEther.mul(5000)
    : pool.minSupporterStake.bn.mul(5000);
};

export const getChallengerHardCap = (pool: Pool): BigNumber => {
  const softCap = getSoftCap(pool);
  if (
    pool.version < "v0.6" ||
    !pool.stakingInfo?.totalAmount ||
    !pool.challengerPayoutRatio ||
    !pool.challengerInfo?.totalAmount
  ) {
    return softCap;
  }

  const poolHardCap = pool.stakingInfo.totalAmount.bn.div(
    pool.challengerPayoutRatio
  );

  const allowedChallengeAmount = poolHardCap.sub(
    pool.challengerInfo.totalAmount.bn
  );

  return softCap.lt(allowedChallengeAmount) ? softCap : allowedChallengeAmount;
};

export const getPoolToken = (pool: PoolDB | Pool): TokenDetails | undefined => {
  if (!pool) return undefined;
  return pool?.version < "v0.6"
    ? getNetworkById(pool.chainId)?.token
    : getToken(pool.chainId, pool.tokenAddress);
};

export const hasUnconfirmedShares = (pool: PoolV06) => {
  return (
    pool.userChallengerInfo?.claimableSharesStartMultiplier &&
    (pool.challengerStartDecayMultiplier?.lt(
      pool.userChallengerInfo.claimableSharesStartMultiplier?.bn
    ) ||
      (pool.challengerStartDecayMultiplier.gt(0) &&
        pool.userChallengerInfo.claimableSharesStartMultiplier?.bn.eq(0)))
  );
};

export const formatTokenAmountArrayMap = (
  tokenArrayMap: TokenAmount[],
  chainId: string
): Record<TokenId, AnteNumber> => {
  if (!tokenArrayMap) return {};

  let tokenAmountMap: Record<TokenId, AnteNumber> = {};

  for (const tokenAmount of tokenArrayMap) {
    const token = getToken(chainId, tokenAmount.token);
    if (!token) continue;
    tokenAmountMap[tokenAmount.token] = formatAnteNumber(
      tokenAmount.amount,
      token.decimals
    );
  }

  return tokenAmountMap;
};

export const formatChainTokenAmountArrayMap = (
  chainTokenArrayMap: ChainTokenAmount[]
): PartialRecord<ChainId, Record<TokenId, AnteNumber>> => {
  if (!chainTokenArrayMap) return {};
  let chainTokenAmountMap: PartialRecord<
    ChainId,
    Record<TokenId, AnteNumber>
  > = {};

  for (const chainTokenAmount of chainTokenArrayMap) {
    const token = getToken(chainTokenAmount.chainId, chainTokenAmount.token);
    if (!token) continue;

    if (!chainTokenAmountMap.hasOwnProperty(chainTokenAmount.chainId)) {
      chainTokenAmountMap[chainTokenAmount.chainId] = {};
    }

    chainTokenAmountMap[chainTokenAmount.chainId][chainTokenAmount.token] =
      formatAnteNumber(chainTokenAmount.amount, token.decimals);
  }

  return chainTokenAmountMap;
};

export const calculateTokenAmountInUsd = (
  chainId: ChainId,
  tokenAmount: Record<Address, AnteNumber>,
  prices: TokenPrice
): number => {
  let total = 0;

  for (const tokenId of Object.keys(tokenAmount)) {
    const token = getToken(chainId, tokenId);

    if (!token) {
      continue;
    }

    total += convertTokenToFiat(token, tokenAmount[tokenId].number, prices);
  }

  return total;
};

export const calculateChainTokenAmountInUsd = (
  chainTokenAmount: Record<ChainId, Record<Address, AnteNumber>>,
  prices: TokenPrice
): number => {
  let total = 0;

  for (const chainId of Object.keys(chainTokenAmount)) {
    total += calculateTokenAmountInUsd(
      chainId as ChainId,
      chainTokenAmount[chainId],
      prices
    );
  }

  return total;
};

export const formatAnteNumber = (
  value: any,
  decimals: number = 18
): AnteNumber => {
  if (!value) return undefined;
  if (isAnteNumber(value)) return value as AnteNumber;

  return {
    number: Number(formatUnits(value, decimals)),
    bn: BigNumber.from(value),
  };
};

export const isAnteNumber = (value: any): boolean => {
  return (
    typeof value === "object" &&
    !Array.isArray(value) &&
    value !== null &&
    value.hasOwnProperty("number") &&
    value.hasOwnProperty("bn")
  );
};

export const isString = (value: any): boolean =>
  typeof value === "string" || value instanceof String;

export const isSolidityType = (value: string): boolean => {
  let noBytesStr = value.replace("int", "").replace("[]", "");
  if (
    value.startsWith("int") &&
    (noBytesStr === "" ||
      (Number(noBytesStr) > 0 &&
        Number(noBytesStr) <= 256 &&
        Number(noBytesStr) % 8 === 0))
  ) {
    return true;
  }

  noBytesStr = value.replace("uint", "").replace("[]", "");
  if (
    value.startsWith("uint") &&
    (noBytesStr === "" ||
      (Number(noBytesStr) > 0 &&
        Number(noBytesStr) <= 256 &&
        Number(noBytesStr) % 8 === 0))
  ) {
    return true;
  }

  noBytesStr = value.replace("bytes", "").replace("[]", "");
  if (
    value.startsWith("bytes") &&
    Number(noBytesStr) > 0 &&
    Number(noBytesStr) <= 32
  ) {
    return true;
  }

  return [
    "boolean",
    "boolean[]",
    "string",
    "string[]",
    "address",
    "address[]",
    "bytes",
  ].includes(value);
};

export const getSupportedChains = (): ChainsMap => {
  const supportedNetworks = (process.env.REACT_APP_SUPPORTED_NETWORKS || "")
    .split(",")
    .filter((v) => !!v);
  if (supportedNetworks.length === 0) return {};

  const supportedChains: ChainsMap = {};

  for (const supportedNetwork of supportedNetworks) {
    if (CHAINS.hasOwnProperty(supportedNetwork)) {
      supportedChains[supportedNetwork] = CHAINS[supportedNetwork];
    }
  }

  return supportedChains;
};

export const numberFormatter = (num: number, digits: number = 2) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "m" },
    { value: 1e9, symbol: "b" },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  var item = lookup
    .slice()
    .reverse()
    .find((item) => {
      return num >= item.value;
    });

  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const formatKeyValueArrToObject = <T>(
  keyValueArr: KeyValue<any>[],
  transform?: (value: string) => T
): Record<string, T> => {
  return keyValueArr.reduce(
    (acc, nameValue) => ({
      ...acc,
      [nameValue.key]: transform ? transform(nameValue.value) : nameValue.value,
    }),
    {}
  );
};

export const formatKeyValueArrToMap = <T>(
  keyValueArr: KeyValue<any>[],
  transform?: (value: string) => T
): Map<string, T> => {
  return keyValueArr.reduce(
    (acc, nameValue) =>
      acc.set(
        nameValue.key,
        transform ? transform(nameValue.value) : nameValue.value
      ),
    new Map()
  );
};

export const pluralize = (
  word: string,
  count: number,
  inclusive: boolean = false
) => {
  var pluralized = count === 1 ? word : `${word}s`;

  return (inclusive ? count + " " : "") + pluralized;
};

export const downloadStringAsFile = (filename: string, text: string) => {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const formatNumber = (num: number): string => {
  if (num > 1e9) {
    return `${Number((num / 1e9).toFixed(2))}b`;
  } else if (num > 1e6) {
    return `${Number((num / 1e6).toFixed(2))}m`;
  } else if (num > 1e3) {
    return `${Number((num / 1e3).toFixed(2))}k`;
  } else {
    return `${Number(num.toFixed(2))}`;
  }
};
