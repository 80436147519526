import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    height: "calc(100vh - 96px)",
    display: "flex",
    justifyContent: "center",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "50%": {
      transform: "rotate(360deg)",
    },
    "100%": {
      transform: "rotate(1080deg)",
    },
  },
  logo: {
    marginTop: 200,
    animation: "$spin 2s ease-out infinite",
  },
});
