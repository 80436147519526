import { Link } from "react-router-dom";
import { Routes } from "utils/routes";
import DropdownIcon from "assets/img/chevron-down.svg";
import { useGlobalStyles } from "utils/styles";
import { linkDropdownStyles } from "./LinkDropdown";
import cn from "classnames";
import { cardStyles } from "style/styles";
import { Hidden } from "@material-ui/core";
import ExternalLink from "components/ExternalLink/ExternalLink";

export const CreateMenuItem = () => {
  const globalStyles = useGlobalStyles();
  const styles = linkDropdownStyles();

  return (
    <div className={styles.root}>
      <Hidden smDown implementation="css">
        <div className={styles.dropdown}>
          Create{" "}
          <img src={DropdownIcon} alt="down" className={styles.leftSpacing} />
          <div className={cn(styles.links, cardStyles().root)}>
            <ExternalLink
              href={process.env.REACT_APP_ANTE_HASH_URL}
              className={globalStyles.noStyleLink}
              showIcon={false}
            >
              <b>NEW</b>&nbsp;Create a Secret
            </ExternalLink>
            <Link to={Routes.createTest} className={globalStyles.noStyleLink}>
              Create Test
            </Link>
            <Link to={Routes.createPool} className={globalStyles.noStyleLink}>
              Create Pool
            </Link>
          </div>
        </div>
      </Hidden>
      <Hidden mdUp implementation="css">
        <div className={styles.dropdownMobile}>Create</div>
        <div className={styles.linksMobile}>
          <li>
            <ExternalLink href={process.env.REACT_APP_ANTE_HASH_URL}>
              <b>NEW</b>&nbsp;Create a Secret
            </ExternalLink>
          </li>
          <li>
            <Link to={Routes.createTest}>Create Test</Link>
          </li>
          <li>
            <Link to={Routes.createPool}>Create Pool</Link>
          </li>
        </div>
      </Hidden>
    </div>
  );
};
