import { gql } from "graphql-tag";

export const COMMUNITY_SCORE_FIELDS = gql`
  fragment CommunityScoreFields on CommunityScoreCollection {
    contributor
    communityPRs
    communityScore
    address
  }
`;
