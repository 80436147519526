import { communityQueryResolver } from "db/graphql-query-resolver";
import {
  COMMUNITY_SCORE_ACCESSOR,
  GET_COMMUNITY_SCORE_NODE,
} from "db/queries/community/communityScore";
import { Exact, Scalars } from "db/queries/types";
import { UseQueryOptions, useQuery, QueryKey } from "react-query";
import { CommunityScoreDB } from "types";

export type GetCommunityScoreQueryVariables = Exact<{
  address: Scalars["String"];
}>;

export type GetCommunityScoreQuery = {
  [key in typeof COMMUNITY_SCORE_ACCESSOR]?: CommunityScoreDB;
};

export const useCommunityScore = (
  address: string,
  options?: UseQueryOptions<CommunityScoreDB>
) => {
  const variables: GetCommunityScoreQueryVariables = {
    address: address,
  };
  const query = GET_COMMUNITY_SCORE_NODE;

  const key = [query, variables] as QueryKey;
  const { data, isLoading } = useQuery(
    key,
    async () => {
      const data = await communityQueryResolver(query, variables);
      return data?.[COMMUNITY_SCORE_ACCESSOR];
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
      enabled: address !== undefined,
      ...options,
    }
  );

  return {
    communityScore: data,
    loading: isLoading,
  };
};
