import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreateTestState, TestDetails } from "state/types";

export const initialState: CreateTestState = {
  txnHash: "",
  test: {
    address: undefined,
    type: undefined,
  },
};

export const createTestSlice = createSlice({
  name: "CreateTest",
  initialState,
  reducers: {
    patchState: (state, action: PayloadAction<Partial<CreateTestState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setTest: (state, action: PayloadAction<TestDetails>) => {
      state.test = action.payload;
    },
    resetTest: (state) => {
      state.test = initialState.test;
    },
    setTxn: (state, action: PayloadAction<string>) => {
      state.txnHash = action.payload;
    },
  },
});

// Actions
export const { patchState, setTest, resetTest, setTxn } =
  createTestSlice.actions;

export default createTestSlice.reducer;
