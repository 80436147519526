import gql from "graphql-tag";
import { COMMUNITY_SCORE_FIELDS } from "db/fragments/community/communityScore";

export const COMMUNITY_SCORES_ACCESSOR = "communityScoreCollections";
export const COMMUNITY_SCORE_ACCESSOR = "communityScoreCollection";

export const GET_COMMUNITY_SCORES_NODE = gql`
${COMMUNITY_SCORE_FIELDS}
query GetCommunityScores {
  ${COMMUNITY_SCORES_ACCESSOR} {
   ...CommunityScoreFields
  }
}
`;

export const GET_COMMUNITY_SCORE_NODE = gql`
${COMMUNITY_SCORE_FIELDS}
query GetCommunityScore($address: String) {
  ${COMMUNITY_SCORE_ACCESSOR}(query: { address: $address }) {
   ...CommunityScoreFields
  }
}
`;
