import { useStyles } from "./ErrorPage.styles";
import { ErrorCard } from "./ErrorCard";

const ErrorPage = () => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <ErrorCard subtitle="Something went wrong." />
    </div>
  );
};

export { ErrorPage };
