import { ErrorCard } from "pages/ErrorPage";
import { useStyles } from "./NotFoundPage.styles";

const NotFoundPage = () => {
  const styles = useStyles();
  return (
    <div className={styles.root} data-testid="not-found-page">
      <ErrorCard subtitle="We can't find that page" />
    </div>
  );
};

export { NotFoundPage };
