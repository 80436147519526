import { useQuery, useQueryClient } from "react-query";
import { RESTRICTED_COUNTRIES } from "utils/constants";

type IPResponse = {
  ip: string;
  city: string;
  region: string;
  region_code: string;
  country: string;
  country_name: string;
  continent_code: string;
  in_eu: boolean;
  postal: string;
  latitude: number;
  longitude: number;
  timezone: string;
  utc_offset: string;
  country_calling_code: string;
  currency: string;
  languages: string;
  asn: string;
  org: string;
};

type GeofencingFetcher = {
  data?: IPResponse;
  isRestricted: boolean;
  loading: boolean;
  error: any;
};

const fetcher = (input: RequestInfo, init?: RequestInit) =>
  fetch(input, init).then((res) => res.json());

const ipUrl = "https://ipapi.co/json/";

export const useGeofencing = (): GeofencingFetcher => {
  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery<IPResponse>(
    ipUrl,
    () => fetcher(ipUrl),
    {
      staleTime: 24 * 60 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: !queryClient.getQueryData(ipUrl),
    }
  );

  return {
    data: data,
    isRestricted: RESTRICTED_COUNTRIES.includes(data?.country),
    loading: isLoading,
    error,
  };
};
