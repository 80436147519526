import { Container } from "@material-ui/core";
import Footer from "components/Footer/Footer";
import { LoadingView } from "components/LoadingView";
import { TopBanner } from "components/TopBanner";
import TopBar from "components/TopBar/TopBar";
import { Suspense } from "react";
import { Route, RouteProps } from "react-router-dom";
import { useLocalStorage } from "react-use-storage";
import { useStyles } from "./AppRoute.styles";

const AppRoute = ({ exact, path, component: Component }: RouteProps) => {
  const [showBanner, setShowBanner] = useLocalStorage(
    "show-ante-warning-banner",
    true
  );

  const styles = useStyles();
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => (
        <div className={styles.root} id="app-page">
          {showBanner && <TopBanner closeBanner={() => setShowBanner(false)} />}
          <TopBar />
          <Suspense fallback={<LoadingView />}>
            <Container maxWidth="xl" className={styles.container}>
              <Component {...props} />
            </Container>
          </Suspense>
          <Footer />
        </div>
      )}
    />
  );
};
export { AppRoute };
