import { DocumentNode } from "graphql";
import * as Realm from "realm-web";
import { fetchGraphQL, Variables } from "./graphql-fetcher";
import jwt, { JwtPayload } from "jsonwebtoken";
import { RealmUserCustomData } from "types";

export const APP_ID = process.env.REACT_APP_REALM_APP_ID;
export const credentials = Realm.Credentials.anonymous();
const graphqlUri = `https://us-east-1.aws.realm.mongodb.com/api/client/v2.0/app/${APP_ID}/graphql`;
export const app = new Realm.App(APP_ID);

export const getUserData = (): RealmUserCustomData => {
  return app.currentUser?.customData as RealmUserCustomData;
};

const getValidAccessToken = async (
  app: Realm.App,
  credentials: Realm.Credentials
) => {
  if (!app.currentUser) {
    await app.logIn(credentials);
  } else {
    /**
     * Refresh the access token and the user's data only if it's expired.
     * This saves some good ms on each request
     */
    if (
      (jwt.decode(app.currentUser.accessToken) as JwtPayload)?.exp <
      Date.now() / 1000
    ) {
      await app.currentUser.refreshCustomData();
    }
  }
  return app.currentUser.accessToken;
};

export const queryResolver = async <T extends any>(
  query: DocumentNode,
  variables?: Variables
): Promise<T> => {
  if (app.currentUser?.providerType === "api-key") {
    await app.currentUser.logOut();
  }

  const accessToken = await getValidAccessToken(app, credentials);

  return fetchGraphQL<T, any>(graphqlUri, query, variables, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const COMMUNITY_APP_ID = process.env.REACT_APP_REALM_COMMUNITY_APP_ID;
const communityApiKey = process.env.REACT_APP_REALM_COMMUNITY_API_KEY;
const communityCredentials = Realm.Credentials.apiKey(communityApiKey);
const communityGraphqlUri = `https://us-east-1.aws.realm.mongodb.com/api/client/v2.0/app/${COMMUNITY_APP_ID}/graphql`;
const communityApp = new Realm.App(COMMUNITY_APP_ID);

export const communityQueryResolver = async <T extends any>(
  query: DocumentNode,
  variables?: Variables
): Promise<T> => {
  const accessToken = await getValidAccessToken(
    communityApp,
    communityCredentials
  );

  return fetchGraphQL<T, any>(communityGraphqlUri, query, variables, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
