type Route =
  | "home"
  | "testDetail"
  | "project"
  | "pollStats"
  | "createPool"
  | "createTest"
  | "disclaimer"
  | "communityLeaderboard"
  | "poll";
type TestDetailParams = {
  testChainId: string;
  testAddress: string;
  poolChainId?: string;
  poolAddress?: string;
};

export const RouteSignature: Record<Route, string> = {
  home: "/",
  testDetail: "/contract/:chainId/:address/:poolChainId/:poolAddress",
  project: "/project/:slugOrName",
  poll: "/project/:projectSlugOrName?poll=:poll",
  pollStats: "/poll-stats/:pollType",
  createPool: "/create-pool",
  createTest: "/create-test",
  disclaimer: "/disclaimer",
  communityLeaderboard: "/community-leaderboard",
};

export const Routes = {
  home: RouteSignature.home,
  testDetail: ({
    testChainId,
    testAddress,
    poolChainId,
    poolAddress,
  }: TestDetailParams) => {
    return RouteSignature.testDetail
      .replace(":chainId", testChainId)
      .replace(":address", testAddress)
      .replace(":poolChainId", poolChainId ?? "")
      .replace(":poolAddress", poolAddress ?? "")
      .replace("//", "/");
  },
  project: (slugOrName: string) => {
    return RouteSignature.project.replace(":slugOrName", slugOrName);
  },
  poll: (projectSlugOrName: string, pollId: string) => {
    return RouteSignature.poll
      .replace(":projectSlugOrName", projectSlugOrName)
      .replace(":poll", pollId);
  },
  pollStats: (pollType: string) => {
    return RouteSignature.pollStats.replace(":pollType", pollType);
  },
  createPool: RouteSignature.createPool,
  createTest: RouteSignature.createTest,
  disclaimer: RouteSignature.disclaimer,
  communityLeaderboard: RouteSignature.communityLeaderboard,
};
