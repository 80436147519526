import { JsonRpcProvider } from "@ethersproject/providers";
import { Contract } from "ethers";
import { isAddress } from "ethers/lib/utils";
import { QueryFunctionContext, useQuery, useQueryClient } from "react-query";
import { DEFAULT_CHAIN } from "utils/constants";
import SnackbarUtils from "utils/SnackbarUtils";
import { useWallet } from "utils/wallet";

const fetcher =
  (library: JsonRpcProvider, abi: any) => (context: QueryFunctionContext) => {
    const signerOrProvider = library
      ? library.getSigner()
      : new JsonRpcProvider(DEFAULT_CHAIN.rpcUrl);
    const args = context.queryKey;
    const [, address, method, params] = args;
    const contract = new Contract(address as string, abi, signerOrProvider);
    return contract[method as string](...((params ?? []) as any[]));
  };

type UseContractOptions = {
  enabled?: boolean;
};

export const useContract = <T>(
  abi: any,
  address?: string,
  method?: string,
  args?: any[],
  options: UseContractOptions = {
    enabled: true,
  }
) => {
  const { ethersProvider, networkId } = useWallet();

  const queryClient = useQueryClient();

  const key = [networkId ? networkId : DEFAULT_CHAIN.id, address, method, args];
  const { data, isLoading, error } = useQuery<T>(
    key,
    fetcher(ethersProvider, abi),
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: !queryClient.getQueryData(key),
      enabled:
        isAddress(address) && method !== undefined && options.enabled !== false,
      onError: () => {
        SnackbarUtils.error("Unable to fetch contract");
      },
    }
  );

  return {
    data,
    loading: isLoading,
    error,
    invalidate: () => {
      queryClient.invalidateQueries({ queryKey: key });
    },
  };
};
