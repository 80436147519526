import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import AnteButton from "components/AnteButton/AnteButton";
import { ModalProps } from "types";
import { Chain, ChainId } from "utils/constants";
import { getSupportedChains } from "utils/utils";
import { useWallet } from "utils/wallet";
import { useStyles } from "./SelectNetworkModal.styles";

type SelectNetworkModalProps = ModalProps & {
  allowedChains?: ChainId[];
};

export const SelectNetworkModal = ({
  allowedChains,
  isShown,
  hide,
}: SelectNetworkModalProps) => {
  const styles = useStyles();
  const { account, setChain } = useWallet();

  const handleChainChange = (chainId: string) => {
    if (account) {
      setChain(chainId);
    }
    hide();
  };

  const _renderSupportedChains = () => {
    const supportedChains = getSupportedChains();
    return Object.keys(supportedChains).map((networkKey) => {
      const network = supportedChains[networkKey] as Chain;

      if (allowedChains && !allowedChains.includes(network.id)) {
        return null;
      }

      return (
        <div
          key={network.id}
          className={styles.networkItem}
          onClick={() => handleChainChange(network.id)}
        >
          {network.logo && (
            <img
              src={network.logo}
              alt={network.label}
              className={styles.logo}
            />
          )}
          <Typography variant={"body1"} className={styles.networkLabel}>
            Switch to {network.label}
          </Typography>
        </div>
      );
    });
  };

  return (
    <Dialog open={isShown} onClose={hide} fullWidth={true} maxWidth="xs">
      <DialogTitle>Unsupported network</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Please connect to a supported network
        </Typography>
        <div className={styles.networksContainer}>
          {_renderSupportedChains()}
        </div>
      </DialogContent>
      <DialogActions>
        <AnteButton onClick={hide}>Dismiss</AnteButton>
      </DialogActions>
    </Dialog>
  );
};
