import XIcon from "assets/img/x.svg";
import { bannerStyles } from "./TopBanner.styles";

const TopBanner = ({ closeBanner }) => {
  const styles = bannerStyles();
  return (
    <div className={styles.root}>
      <div className={styles.text}>
        While Ante v0.6 has been audited (report coming soon), please exercise
        caution when interacting with smart contracts.
      </div>
      <div className={styles.closeButton} onClick={closeBanner}>
        <img src={XIcon} alt="close" />
      </div>
    </div>
  );
};

export { TopBanner };
