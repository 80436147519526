import { makeStyles } from "@material-ui/core";
import colors from "style/colors";

export const globalStyles = {
  link: {
    color: colors.linkBlue,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  noDecoration: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  noStyleLink: {
    color: "inherit",
    "&:hover": {
      textDecoration: "none",
    },
  },
  textLeft: {
    textAlign: "left" as const,
  },
  textCenter: {
    textAlign: "center" as const,
  },
  textRight: {
    textAlign: "right" as const,
  },
  alignLeft: {
    alignItems: "flex-start",
  },
  alignCenter: {
    alignItems: "center",
  },
  alignRight: {
    alignItems: "flex-end",
  },
  warningBox: {
    border: `1px solid ${colors.warningYellow}`,
    background: colors.warningSecondaryYellow,
    padding: "0.5rem",
    borderRadius: "0.5rem",
  },
  infoBox: {
    border: `1px solid ${colors.statPurple}`,
    background: colors.statPurple15,
    padding: "0.5rem",
    borderRadius: "0.5rem",
  },
  colorPurple: {
    color: colors.statPurple,
  },
};

export const useGlobalStyles = makeStyles(globalStyles);
