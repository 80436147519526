import { makeStyles, Theme } from "@material-ui/core";
import colors from "style/colors";

export const useButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    textTransform: "none",
    color: colors.greyscale,
    padding: "8px",
    borderColor: colors.linkBlue,
    borderRadius: "8px",
    backgroundColor: "white",
    "&:hover": {
      bordercolor: colors.primaryBlueLite,
      backgroundColor: "white",
    },
    display: "flex",
    justifyContent: "space-between",
    gap: "4px",
    minWidth: "248px",
    maxWidth: "320px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "160px",
    },
  },
  balance: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
}));

export const usePopoverStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    width: "300px",
    margin: "0px",
  },
  balanceTitle: {
    color: colors.greyscale,
  },
  linkContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconLink: {
    cursor: "pointer",
    background: colors.black5,
    display: "inline-flex",
    borderRadius: "50%",
    padding: "0.375rem",
    color: colors.black54,
    marginLeft: "0.5rem",
  },
  paper: {
    borderRadius: "0.5rem",
  },
}));
