import { makeStyles } from "@material-ui/core";
import colors from "style/colors";

export const bannerStyles = makeStyles({
  root: {
    backgroundColor: colors.warningYellow,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 600,
    padding: "12px",
    transition: "all .1s",
    position: "relative",
  },
  text: {
    width: "75%",
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: "24px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  link: {
    fontWeight: 600,

    "&:hover": {
      color: colors.linkBlue,
      textDecoration: "underline",
    },
  },
});
