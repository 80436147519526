import { useStyles } from "./AnteButton.styles";
import cn from "classnames";
import React from "react";
import { Link, LinkProps } from "react-router-dom";

export type AnteButtonVariant =
  | "primary1"
  | "primary2"
  | "secondary1"
  | "text"
  | "dangerText";
export type AnteButtonSize = "small" | "large";

interface AnteButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: AnteButtonVariant;
  size?: AnteButtonSize;
  fullWidth?: boolean;
  Component?: "a" | "button" | any;
  href?: string;
}

const AnteButton = (props: AnteButtonProps) => {
  const styles = useStyles();
  const {
    Component,
    variant,
    children,
    size,
    fullWidth,
    className,
    ...btnProps
  } = props;

  const sizeStyle = styles[size];
  const buttonProps: React.ButtonHTMLAttributes<"button"> = {};
  const linkProps: LinkProps = {
    to: "",
  };

  let ComponentProp = props.Component ?? "button";

  if (ComponentProp === "button") {
    const type = props.type || "button";

    buttonProps.type = type;
    buttonProps.disabled = props.disabled;
  } else if (ComponentProp === "a" || props.href) {
    ComponentProp = Link;
    linkProps.to = props.href;
  }

  return React.createElement(
    ComponentProp,
    {
      className: cn(
        styles.root,
        styles[variant || "text"],
        sizeStyle,
        {
          [styles.fullWidth]: fullWidth,
        },
        className
      ),
      ...btnProps,
      ...buttonProps,
      ...linkProps,
    },
    children
  );
};

export default AnteButton;
