import React from "react";
import useClipboard from "react-use-clipboard";
import { useWallet } from "utils/wallet";
import { formatTokenValue, shortenString, toLocaleNumber } from "utils/utils";
import Popover from "@material-ui/core/Popover";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import OpenInNew from "@material-ui/icons/OpenInNew";
import SvgIcon from "@material-ui/core/SvgIcon";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { useSnackbar } from "notistack";
import { useButtonStyles, usePopoverStyles } from "./WalletPopover.styles";
import { CHAIN_EXPLORERS, getTokenAddress } from "utils/constants";
import { useMediaQuery, useTheme } from "@material-ui/core";
import KPI from "components/KPI/KPI";
import { useContract } from "hooks/useContract";
import IERC20Abi from "abi/IERC20.json";
import { BigNumber } from "ethers";
import colors from "style/colors";
import { formatUnits } from "@ethersproject/units";
import AnteButton from "components/AnteButton/AnteButton";
import { useSelectDisplayedToken } from "state/selectors";
import { useCommunityScore } from "hooks/community/communityScores/useCommunityScore";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { Link as RouterLink } from "react-router-dom";

const WalletPopover = () => {
  const { account, networkId, networkName, disconnect, balance, token } =
    useWallet();
  const { communityScore } = useCommunityScore(account);
  const displayedToken = useSelectDisplayedToken();

  const tokenAddress = getTokenAddress(networkId, displayedToken.symbol);
  const { data: tokenBalance } = useContract<BigNumber>(
    IERC20Abi,
    tokenAddress,
    "balanceOf",
    [account],
    {
      enabled: account !== undefined && tokenAddress !== undefined,
    }
  );

  const buttonStyles = useButtonStyles();
  const popoverStyles = usePopoverStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onDisconnect = () => {
    disconnect();
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [, setCopied] = useClipboard(account ?? "", {
    successDuration: 5000,
  });

  const { enqueueSnackbar } = useSnackbar();
  const handleCopy = () => {
    setCopied();
    enqueueSnackbar("Address copied to clipboard", {
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  const getDisplayedBalance = () => {
    return tokenBalance
      ? formatTokenValue(
          toLocaleNumber(
            Number(formatUnits(tokenBalance, displayedToken.decimals))
          ),
          displayedToken.symbol
        )
      : `${formatTokenValue(balance.formattedString, token.symbol)}`;
  };

  return (
    <div>
      <AnteButton
        variant="primary2"
        className={buttonStyles.root}
        onClick={handleClick}
      >
        {!isMobile && balance && (
          <Typography className={buttonStyles.balance}>
            {getDisplayedBalance()}
          </Typography>
        )}
        <Typography variant="body1">{shortenString(account)}</Typography>
        <ArrowDropDownIcon />
      </AnteButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: "right",
        }}
        classes={{ paper: popoverStyles.paper }}
      >
        <Container className={popoverStyles.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="body1">
                    {shortenString(account)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {networkName}
                  </Typography>
                </Grid>
                <Grid item>
                  <Link
                    onClick={handleCopy}
                    color="textSecondary"
                    className={popoverStyles.iconLink}
                  >
                    <SvgIcon fontSize="small">
                      <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                    </SvgIcon>
                  </Link>
                  {CHAIN_EXPLORERS[networkId] && (
                    <a
                      href={`${CHAIN_EXPLORERS[networkId].address}${account}`}
                      target="_blank"
                      rel="noreferrer"
                      className={popoverStyles.iconLink}
                    >
                      <OpenInNew fontSize="small" />
                    </a>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <KPI
                label={
                  tokenBalance ? `${displayedToken.symbol} Balance` : "Balance"
                }
                value={getDisplayedBalance()}
                valueColor={colors.black69}
              />
            </Grid>
            <Grid item xs={12}>
              <KPI
                label="Community score"
                value={
                  communityScore ? String(communityScore?.communityScore) : "-"
                }
                info={
                  <>
                    <Typography variant="body2">
                      Increase community score by PRing Ante Tests. Purely for
                      fun!
                    </Typography>
                    <Typography variant="body2">
                      For the avoidance of doubt, the Ante Community score is
                      not an inducement or promise for future value or rewards,
                      and is merely a fun way to learn more about Ante while
                      also building your community reputation. As a reminder,
                      your participation in the Ante Community score
                      [program/campaign] is subject to Ante's{" "}
                      <ExternalLink href="https://www.ante.finance/tos">
                        Terms of Service
                      </ExternalLink>{" "}
                      and <RouterLink to="/disclaimer">Disclaimer</RouterLink>.
                      Always DYOR (do your own research) before interacting with
                      any smart contracts.
                    </Typography>
                  </>
                }
                valueColor={colors.black69}
              />
            </Grid>
          </Grid>
          <br />
          <AnteButton variant="secondary1" onClick={onDisconnect} fullWidth>
            Disconnect
          </AnteButton>
        </Container>
      </Popover>
    </div>
  );
};

export default WalletPopover;
