import { ethers } from "ethers";
import { Wallet } from "./wallet";

export const getSourceCodeFromExplorer = async (
  address: string,
  baseUrl: string = "https://api.etherscan.io/",
  apiKey: string = process.env.REACT_APP_ETHERSCAN_API_KEY
): Promise<any> => {
  const etherscanApi = `${baseUrl}api?module=contract&action=getsourcecode&address=${address}&apikey=${apiKey}`;
  const response = await fetch(etherscanApi);
  const json = await response.json();
  return json.result;
};

export const getCodeCall = async (wallet: Wallet, address: string) => {
  if (!wallet.provider) {
    return "Not connected to wallet.";
  }
  const web3 = new ethers.providers.Web3Provider(wallet.provider);
  return await web3.getCode(address);
};
