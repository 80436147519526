import Web3Onboard, { OnboardAPI } from "@web3-onboard/core";
import { Chain } from "@web3-onboard/common";
import injectedModule from "@web3-onboard/injected-wallets";
import AnteLogo from "assets/ante/ante-logo.svg";
import gnosisModule from "@web3-onboard/gnosis";
import walletConnectModule from "@web3-onboard/walletconnect";
import coinbaseWalletModule from "@web3-onboard/coinbase";
import { ChainId } from "utils/constants";
import type { WalletInit } from "@web3-onboard/common";
import { getSupportedChains } from "utils/utils";

const appUrl = "https://app.ante.finance";

const injected = injectedModule();
const coinbase = coinbaseWalletModule({ darkMode: true });

// We are forcing the cast to WalletInit in order to avoid a Typescript error
// The cast can be removed after OnboardJS has a new version for these connectors
const gnosis = gnosisModule() as WalletInit;
const walletConnect = walletConnectModule({
  qrcodeModalOptions: {
    mobileLinks: [
      "rainbow",
      "metamask",
      "argent",
      "trust",
      "imtoken",
      "pillar",
    ],
  },
}) as WalletInit;

export const ONBOARD_CHAINS = Object.values(getSupportedChains()).reduce(
  (prevResult, chain) => {
    prevResult[chain.id] = {
      id: chain.id,
      token: chain.token.symbol,
      label: chain.label,
      rpcUrl: chain.rpcUrl,
    };
    return prevResult;
  },
  {} as Record<ChainId, Chain>
);

export let web3Onboard: OnboardAPI | null = null;

export const initOnboard = (): OnboardAPI => {
  web3Onboard = Web3Onboard({
    wallets: [injected, gnosis, walletConnect, coinbase],
    chains: Object.values(ONBOARD_CHAINS),
    appMetadata: {
      name: "Ante",
      icon: AnteLogo,
      logo: AnteLogo,
      description: appUrl,
    },
    accountCenter: {
      desktop: {
        enabled: false,
      },
      mobile: {
        enabled: false,
      },
    },
  });
  return web3Onboard;
};
