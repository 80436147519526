import { Link, Typography } from "@material-ui/core";
import { useStyles } from "./RestrictedWalletPage.styles";
import { ErrorCard } from "pages/ErrorPage";

export const RestrictedWalletPage = () => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <ErrorCard
        subtitle="Your wallet is sanctioned by the US government, therefore you cannot access this application."
        text={
          <Typography variant={"body1"}>
            You can{" "}
            <Link>
              <a href="https://ante.finance/feedback" rel="noopener">
                drop us a line
              </a>
            </Link>{" "}
            if you believe this is an error.
          </Typography>
        }
      />
    </div>
  );
};
