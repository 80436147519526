import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreatePoolState } from "state/types";

export const initialState: CreatePoolState = {
  txnHash: "",
  poolAddress: undefined,
};

export const createTestSlice = createSlice({
  name: "CreateTest",
  initialState,
  reducers: {
    patchState: (state, action: PayloadAction<Partial<CreatePoolState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setPoolAddress: (state, action: PayloadAction<string>) => {
      state.poolAddress = action.payload;
    },
    setTxn: (state, action: PayloadAction<string>) => {
      state.txnHash = action.payload;
    },
  },
});

// Actions
export const { patchState, setPoolAddress, setTxn } = createTestSlice.actions;

export default createTestSlice.reducer;
