import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { NotFoundPage } from "pages/NotFoundPage";
import { AppRoute } from "components/AppRoute";
import { useFirewall } from "hooks/useFirewall";
import { lazy, useEffect } from "react";
import { AnalyticsEvents, sendEvent } from "analytics/analytics";
import { setDisplayedToken } from "state/localization";
import { useDispatch } from "react-redux";
import { useWallet } from "utils/wallet";
import { RouteSignature } from "utils/routes";

const LeaderboardPage = lazy(() => import("./pages/Leaderboard"));
const AnteTestDetail = lazy(() => import("./pages/AnteTestDetail"));
const CommunityLeaderboard = lazy(() => import("./pages/CommunityLeaderboard"));
const ProfilePage = lazy(() => import("./pages/ProfilePage"));
const CreatePool = lazy(() => import("./pages/CreatePool"));
const CreateTestPage = lazy(() => import("./pages/CreateTestPage"));
const DisclaimerPage = lazy(() => import("./pages/DisclaimerPage"));
const PollStatsPage = lazy(() => import("./pages/PollStatsPage"));

const Routes = () => {
  const history = useHistory();
  const { token } = useWallet();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) return;
    dispatch(setDisplayedToken(token));
  }, [dispatch, token]);

  const trackPageView = () => {
    sendEvent(AnalyticsEvents.pageView, {
      path:
        window.location.pathname +
        window.location.search +
        window.location.hash,
    });
  };

  useEffect(() => {
    // Track the first pageview upon load
    trackPageView();
    // Track the subsequent pageviews
    history.listen(trackPageView);
  }, [history]);

  const { NoticeElement, isRestricted, loading } = useFirewall();
  if (!loading && isRestricted) {
    return NoticeElement;
  }

  return (
    <Switch>
      <AppRoute path={RouteSignature.home} exact component={LeaderboardPage} />
      <AppRoute path={RouteSignature.testDetail} component={AnteTestDetail} />
      <AppRoute path="/contract/:chainId/:address" component={AnteTestDetail} />
      <AppRoute path={RouteSignature.project} component={ProfilePage} />
      <Redirect from="/protocol/*" to={"/project/*"} />
      <AppRoute path={RouteSignature.pollStats} component={PollStatsPage} />
      <AppRoute path={RouteSignature.createPool} component={CreatePool} />
      <AppRoute path={RouteSignature.createTest} component={CreateTestPage} />
      <AppRoute path={RouteSignature.disclaimer} component={DisclaimerPage} />
      <AppRoute
        path={RouteSignature.communityLeaderboard}
        component={CommunityLeaderboard}
      />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

type AppProps = {
  hideLoader: () => void;
};

const App = ({ hideLoader }: AppProps) => {
  useEffect(hideLoader, [hideLoader]);

  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

export default App;
