import antePoolsReducer from "state/antepools/antepools";
import localizationReducer from "state/localization";
import { AnyAction, configureStore } from "@reduxjs/toolkit";
import createTestReducer from "state/createTest/createTest";
import createPoolReducer from "state/createPool/createPool";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { CreatePoolState, CreateTestState } from "./types";

const persistConfig = {
  key: "createTest",
  storage,
};

const persistedCreateTestReducer = persistReducer<CreateTestState, AnyAction>(
  persistConfig,
  createTestReducer
);

const persistedCreatePoolReducer = persistReducer<CreatePoolState, AnyAction>(
  persistConfig,
  createPoolReducer
);

export const store = configureStore({
  reducer: {
    pools: antePoolsReducer,
    localization: localizationReducer,
    createTest: persistedCreateTestReducer,
    createPool: persistedCreatePoolReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
