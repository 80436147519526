import { useGeofencing } from "./useGeofencing";
import { RestrictedCountryPage } from "../pages/RestrictedCountryPage";
import { BLACKLISTED_ADDRESSES } from "utils/constants";
import { useWallet } from "utils/wallet";
import { RestrictedWalletPage } from "pages/RestrictedWalletPage";

type FirewallResponse = {
  isRestricted: boolean;
  loading: boolean;
  error: any;
  NoticeElement?: React.ReactElement;
};

export const useFirewall = (): FirewallResponse => {
  const { loading, isRestricted: isCountryRestricted, error } = useGeofencing();
  const { account } = useWallet();
  const isBlacklisted = BLACKLISTED_ADDRESSES.includes(account?.toLowerCase());

  const getNoticeElement = () => {
    if (isBlacklisted) {
      return <RestrictedWalletPage />;
    }

    if (isCountryRestricted) {
      return <RestrictedCountryPage />;
    }

    return null;
  };

  return {
    isRestricted: isBlacklisted || isCountryRestricted,
    loading,
    error,
    NoticeElement: getNoticeElement(),
  };
};
