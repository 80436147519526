import { makeStyles } from "@material-ui/core";
import ExternalLinkIcon from "assets/img/icons/external-link.svg";
import colors from "style/colors";

export type ExternalLinkProps =
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    showIcon?: boolean;
    handleOnClick?: () => void;
  };

const useStyles = makeStyles({
  link: {
    color: colors.linkBlue,
    display: "inline-flex",
    alignItems: "center",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

const ExternalLink: React.FunctionComponent<ExternalLinkProps> = (props) => {
  const { children, showIcon, handleOnClick, ...rest } = props;
  const styles = useStyles();

  return (
    <a
      onClick={handleOnClick}
      rel="noopener noreferrer"
      target="_blank"
      className={styles.link}
      {...rest}
    >
      {children}
      {(showIcon ?? true) && (
        <>
          &nbsp;
          <img src={ExternalLinkIcon} alt="link" width={14} height={14} />
        </>
      )}
    </a>
  );
};

export default ExternalLink;
