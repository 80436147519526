import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import colors from "./colors";

export const accordionStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      boxShadow: "none",
      borderRadius: "0",
      padding: "0",
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        margin: "0",
        minHeight: "48",
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      // @ts-ignore
      fontWeight: theme.typography.fontWeightRegular,
    },
    content: {
      "&$expanded": {
        margin: "0",
      },
    },
    expanded: {
      minHeight: "48",
      margin: "0",
    },
  })
);

export const panelStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "8px",
      boxShadow:
        "0px 5px 16px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.1)",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "16px",
      },
    },
  })
);

export const cardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "8px",
      boxShadow:
        "0px 5px 16px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.1)",
    },
  })
);

export const containerStyles = makeStyles({
  root: {
    borderRadius: "16px",
    boxShadow:
      "0px 5px 32px 0px rgba(0, 0, 0, 0.1), 0px 0px 2px 0px rgba(0, 0, 0, 0.1)",
  },
  rainbowBorder: {
    border: "2px solid",
    borderRadius: "16px",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    borderImage: "linear-gradient(90deg, #0064D6 0%, #DE458E 100%)",
    borderImageSlice: 0,
    backgroundImage:
      "linear-gradient(white, white), linear-gradient(90deg, #0064D6 0%, #DE458E 100%)",
  },
});

export const useInputStyles = makeStyles({
  input: {
    width: "100%",
  },
  inputLabel: {
    fontWeight: 500,
    marginBottom: "0.25rem",
  },
  inputContainer: {
    position: "relative",
  },
  inputWarning: {
    borderColor: colors.failedRed,
    backgroundColor: colors.failedRed10,
    color: colors.failedText,
  },
  inputAdornment: {
    color: colors.black5,
    paddingRight: "0.5rem",
    marginLeft: "0 !important",
  },
  error: {
    color: colors.failedRed,
  },
  helperContainer: {
    display: "flex",
    margin: "4px 0 0 0",
    justifyContent: "space-between",
    alignItems: "flex-start",
    gap: "16px",
    color: colors.failedRed,
    marginTop: "4px",
  },
});

export const useLayoutStyles = makeStyles({
  noContentContainer: {
    marginTop: "8rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
