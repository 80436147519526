import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import colors from "style/colors";
import { useTypography } from "style/typography";
import QuestionIcon from "assets/img/icons/question-icon.svg";
import { isString } from "utils/utils";
import cn from "classnames";

const useStyles = makeStyles({
  kpiValue: {
    display: "flex",
    gap: "8px",
    alignItems: "flex-end",
  },
  kpiLabel: {
    color: colors.black69,
  },
  kpiSubtext: {
    color: colors.black42,
  },
});

type KpiSize = "large" | "medium" | "small";
export interface KPIProps {
  label: React.ReactNode;
  value: React.ReactNode;
  badge?: React.ReactNode;
  valueColor?: string;
  info?: string | React.ReactNode;
  footnote?: React.ReactNode;
  footnoteColor?: string;
  status?: React.ReactNode;
  statusColor?: string;
  tooltip?: React.ReactNode;
  size?: KpiSize;
  isLoading?: boolean;
}

const fontSize: Record<KpiSize, number> = {
  small: 16,
  medium: 24,
  large: 32,
};

function KPI({
  label,
  value,
  badge,
  info,
  footnote,
  status,
  valueColor = colors.statPurple,
  statusColor = "#7777777",
  tooltip,
  size = "medium",
  isLoading = false,
}: KPIProps) {
  const styles = useStyles();
  const typography = useTypography();

  const kpiStatStyle: Record<KpiSize, string> = {
    small: typography.kpiSmall,
    medium: typography.kpiMedium,
    large: typography.kpiLarge,
  };

  const kpiLabelStyle: Record<KpiSize, string> = {
    small: typography.labelSmall,
    medium: typography.labelMedium,
    large: typography.labelLarge,
  };

  const kpiFootnoteStyle: Record<KpiSize, string> = {
    small: typography.subtextSmall,
    medium: typography.subtextMedium,
    large: typography.subtextMedium,
  };

  const renderValue = () => {
    let formattedValue = isString(value) ? (
      <Typography className={kpiStatStyle[size]} style={{ color: valueColor }}>
        {value}
      </Typography>
    ) : (
      value
    );

    if (isLoading) {
      formattedValue = (
        <Skeleton variant="text" height={fontSize[size] * 1.4} width={80} />
      );
    }

    return tooltip ? (
      <Tooltip
        title={tooltip}
        placement="top"
        arrow
        enterTouchDelay={0}
        interactive
      >
        <div className={styles.kpiValue}>
          {formattedValue} {badge}
        </div>
      </Tooltip>
    ) : (
      <div className={styles.kpiValue}>
        {formattedValue} {badge}
      </div>
    );
  };

  const renderInfo = () => {
    if (!info) return null;
    return (
      <Tooltip
        title={info}
        placement="top"
        arrow
        enterTouchDelay={0}
        interactive
      >
        <span>
          &nbsp;
          <img src={QuestionIcon} alt="info" width={14} height={14} />
        </span>
      </Tooltip>
    );
  };

  const renderLabel = () => {
    if (!label) return null;
    return (
      <Typography className={cn(kpiLabelStyle[size], styles.kpiLabel)}>
        {label}
        {renderInfo()}
      </Typography>
    );
  };

  const renderFootnote = () => {
    if (!footnote) return null;
    return (
      <Typography
        variant="body2"
        className={cn(kpiFootnoteStyle[size], styles.kpiSubtext)}
      >
        {isLoading ? <Skeleton width={100} /> : footnote}
      </Typography>
    );
  };

  return (
    <div data-testselector={`kpi-${label}`}>
      {renderLabel()}
      {renderValue()}
      {status && (
        <Typography variant="caption" style={{ color: statusColor }}>
          {status}
        </Typography>
      )}
      {renderFootnote()}
    </div>
  );
}

export default KPI;
