import { useSelector } from "react-redux";

import { State } from "./types";

export const useSelectTxnPending = () => {
  return useSelector((state: State) => state.pools.isTxnPending);
};

export const useSelectTxnHash = () => {
  return useSelector((state: State) => state.pools.txnHash);
};

export const useSelectDisplayedToken = () => {
  return useSelector((state: State) => state.localization.token);
};

export const useSelectCreateTest = () => {
  return useSelector((state: State) => state.createTest);
};

export const useSelectCreatePool = () => {
  return useSelector((state: State) => state.createPool);
};
