import { makeStyles, Theme } from "@material-ui/core";
import colors from "style/colors";

export const useButtonStyles = makeStyles((theme) => ({
  root: {
    textTransform: "none",
    color: colors.greyscale,
    padding: "0.5rem",
    borderColor: colors.linkBlue,
    borderRadius: "0.5rem",
    backgroundColor: "white",
    "&:hover": {
      borderColor: colors.primaryBlueLite,
      backgroundColor: "white",
    },
    display: "flex",
    justifyContent: "space-between",
    width: "180px",
    [theme.breakpoints.down("md")]: {
      width: "72px",
    },
    "& .MuiButton-label": {
      gap: "0.5rem",
      [theme.breakpoints.down("md")]: {
        gap: "0.25rem",
      },
    },
  },
  text: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  logo: {
    borderRadius: "1rem",
    width: "1.5rem",
    height: "1.5rem",
  },
  label: {
    display: "inherit",
    alignItems: "inherit",
    justifyContent: "inherit",
    gap: "0.5rem",
    width: "100%",
  },
}));

export const usePopoverStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: "1rem",
    marginTop: "0.5rem",
  },
  container: {
    padding: theme.spacing(2),
    width: "240px",
    margin: "0px",
  },
  network: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: colors.purple10,
      borderRadius: "0.5rem",
    },
  },
  selected: {
    fontWeight: 600,
  },
}));
