import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocalizationState } from "state/types";
import { Currency } from "types/Currency";
import { TokenDetails, TOKENS } from "utils/constants";

const initialState: LocalizationState = {
  currency: {
    code: "USD",
    symbol: "$",
  },
  token: TOKENS.ETH,
};

export const localizationSlice = createSlice({
  name: "Localization",
  initialState,
  reducers: {
    setCurrency: (state, action: PayloadAction<Currency>) => {
      state.currency = action.payload;
    },
    setDisplayedToken: (state, action: PayloadAction<TokenDetails>) => {
      state.token = action.payload;
    },
  },
});

export const { setDisplayedToken, setCurrency } = localizationSlice.actions;

export default localizationSlice.reducer;
