const SEPARATOR = ":";
export const ROOT_KEY = "ante-finance";
export const CONNECTED_WALLET_KEY = [ROOT_KEY, "connectedWallet"].join(
  SEPARATOR
);
export const SELECTED_CHAIN_KEY = [ROOT_KEY, "selectedChain"].join(SEPARATOR);

const getConnectedWallet = (): string | null => {
  return localStorage.getItem(CONNECTED_WALLET_KEY);
};

const setConnectedWallet = (wallet: string): void => {
  localStorage.setItem(CONNECTED_WALLET_KEY, wallet);
};

const removeConnectedWallet = (): void => {
  localStorage.removeItem(CONNECTED_WALLET_KEY);
};

export const Storage = {
  getConnectedWallet,
  setConnectedWallet,
  removeConnectedWallet,
};
